.ht-img-ratio {
    position: relative;
    overflow: hidden;
}
.ht-img-ratio:before {
    content: '';
    display: block;
    padding-bottom: 75%;
}
.ht-img-ratio.ratio100:before {
    padding-bottom: 100%;
}
.ht-img-ratio > .ht-img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.ht-img-ratio > .ht-img > img {
    width: 100%;
}
.row-flex {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
[class*="align-items"] {
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
}
.align-items--between{
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.align-items--center {
    -webkit-justify-content: center;
    justify-content: center;
}
@media screen and (min-width: 481px) {
    .ht-hidden {
        display: none;
    }
}
/*header*/
.header{
    box-shadow: 0px 2px 5px #999;
    position: relative;
    z-index: 111;
}
.header__topBar {
    background: #fff;
    border-top: 5px solid #FD102A;
    width: 100%;
}
.topContact{
    background: #FD102A;
    color: #fff;
    float: right;
    font-size: 13px;
    height: 23px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 78px;
    position: relative;
}
.topContact:before{
    border-color: #FD102A transparent transparent;
    border-style: solid;
    border-width: 23px 0 0 23px;
    content: '';
    left: -23px;
    position: absolute;
}
.topContact ul {
    float: left;
    padding: 2px 0 0;
}
.topContact li {
    float: left;
    line-height: 13px;
}
.topContact li a {
    color: #fff;
    font-size: 13px;
}
.topContact li a:hover {
    color: #FD102A;
}
.topContact p {
    float: left;
    font-weight: bold;
    margin-bottom: 0;
    padding-left: 8px;
}
/*header bot*/
.hd-logo {
    float: left;
    line-height: 0;
    margin: 15px 0 15px;
}
.hd-logo img{
    height: 70px;
    float: left;
}
.hd-logo__name {
    text-transform: uppercase;
    float: left;
    margin-left: 15px;
}
.hd-logo__name p{
    margin-bottom: 0;
    line-height: 1;
    text-align: center;
    font-family: 'Play';
    font-size: 22px;
    letter-spacing: 1px;
    padding-top: 8px;
}
.hd-logo__name p:first-child {
    border-bottom: 1px solid #eee;
    font-family: 'Play';
    color: #FD102A;
    font-size: 22px;
    padding-top: 0;
    padding-bottom: 7px;
}
/*menu*/
.hd-menu {
    float: right;
    display: flex;
    align-items: center;
    padding: 25px 0;
}
.hd-menu >div.language{
    margin-left: 20px;
    line-height: 0;
}
.hd-menu > ul > li {
    float: left;
    line-height: 20px;
    margin-bottom: 0;
    z-index: 99;
}
.hd-menu > ul > li > a {
    color: #333;
    display: block;
    padding: 0 13px;
    display: inline-block;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
    text-transform: uppercase;
}
.hd-menu > ul > li > a img{
    border: 1px solid #eee;
}
.hd-menu > ul > li:last-child a {
    padding-right: 0;
}
.hd-menu > ul > li a:hover, .hd-menu > ul > li.is-active > a  {
    color: #FD102A;
}
.hd-menu > ul > li .sub-menu {
  position: absolute;
  z-index: 112;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  transition: 0.25s ease;
}
.hd-menu > ul > li .sub-menu ul {
  padding: 15px 15px;
  margin-top: 28px;
  border-top: 4px solid #FD102A;
  background-color: #FFFFFF;
}
.hd-menu > ul > li .sub-menu ul li a {
  font-size: 14px;
  display: block;
  position: relative;
  padding: 7px 12px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.hd-menu > ul > li .sub-menu ul li a:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  font-size: 0.86em;
  top: 0.67em;
  left: 0;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.hd-menu > ul > li .sub-menu ul li a:hover {
  color: #FF6600;
}
.hd-menu > ul > li .sub-menu ul li a:hover:before {
  color: #FF6600;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.hd-menu > ul > li.is-focus > .sub-menu {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1200px) {
  .hd-menu > ul > li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
  }
}

/*footer*/
.footer-top span{
    text-transform: uppercase;
    font-size: 17px;
    line-height: 50px;
    padding: 0px 10px;
}
.footer-top span, form{
    display: inline-block;
    align-self:center;
    vertical-align: -webkit-baseline-middle;
}
.footer-center{
    background: #c1061a;
    color: #fff;
    padding: 30px 0px;
} 
.footer-center a{
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 5px;
}
.footer-center a i{
    margin-right: 5px;
    color: #ccc;
    font-size: 12px;
}
.footer-center .title{
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 15px;
}
.footer-center .title:after{
    content: '';
    display: block;
    width: 20px;
    height: 1px;
    background: #FD102A;
    margin-top: 5px;
}
.footer-center .title >span{
    font-family: 'Play';
}
.footer-center .title i{
    font-size: 13px;
    color: #FD102A;
}
.footer-center .logo{
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}
.footer-center .text{
    text-align: center;
    margin-top: 20px;
}
.footer-logo >a{
    display: block;
    text-align: center;
}
.footer-logo >a >img{
    width: 35%;
    display: inline-block;
}
.footer-logo >a > .hd-logo__name{
    width: 55%;
    display: inline-block;
    float: none;
    vertical-align: top;
    margin-top: 25px;
}
.footer-logo >a > .hd-logo__name p{
    font-size: 9px;
}
.footer-logo >a > .hd-logo__name p:first-child{
    font-size: 16px;
    color: #fff;
}
.contact_info ul li{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin-bottom: 5px;
}
.modified{
    margin-top: -7px;
}
.contact_info ul li span{
    float: left;
    width: calc(100% - 40px);
}
.contact_info ul li span:first-child{
    width: 30px;
    height: 30px;
    line-height: 22px;
    border-radius: 5px;
    text-align: center;
}
.ft_social {
    line-height: 0;
    margin-left: 8px;
}
.ft_social a {
    display: inline-block;
}
.footer-bottom{
    background:#FD102A;
    font-size: 13px;
    padding: 5px 0;
}
.footer-bottom__wrap{
    display: flex;
}
.footer-bottom__wrap .pull-left{
    align-self:center;
    flex:1;
    color:#fff;
    font-size: 11px;
}
.footer-bottom__wrap .pull-right{
    align-self:center;
    text-align: right;
    flex:1;
    color:#fff;
    font-size: 11px;
}
.footer-bottom__wrap .pull-right span{
    transform: translateY(-3px);
    display: inline-block;
    margin-right: 3px;
}

.news-section {
    background: #eee;
    padding: 15px;
    padding-bottom: 7px;
}
.news-section .news-item{
    border: 1px solid #eee;
    padding: 10px 20px;
    padding-top: 15px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 0px 5px 5px #ccc;
}
.news-item__detail >a{
    font-size: 15px;
    font-weight: 600;
    color: #555;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
}
.news-item__detail >div{
    color: #555;
    line-height: 18px;
}
.news-item__img{
    width: 40%;
    float: left;

}
.news-item__detail{
    width: 60%;
    float: left;
    padding-left: 15px;
}




.main {
    margin-top: 25px;
}
.shadow {
    background: -moz-radial-gradient(circle farthest-corner at center top, #f2f2f2 0%, white 70%);
    background: -ms-radial-gradient(circle farthest-corner at center top, #f2f2f2 0%, white 70%);
    background: -o-radial-gradient(circle farthest-corner at center top, #f2f2f2 0%, white 70%);
    background: radial-gradient(circle farthest-corner at center top, #f2f2f2 0%, white 70%);
    border-radius: 5px;
    height: 5px;
    margin-bottom: 35px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}
.mp-title {
    color: #FD102A;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    opacity: 0.8;
    text-align: center;
}
.mp-title::after {
    background-color: #FD102A;
    content: '';
    display: block;
    height: 1px;
    margin: 5px auto 10px;
    width: 40px;
}
.main__services, .gallerys-items{
    padding-bottom: 20px;
    padding-top: 20px;
}

.card--title .overlay, .ellipsis{
    height: auto !important;
    overflow: hidden;
    text-align: center; 
    text-overflow: ellipsis;
    white-space: nowrap;
}
a > .card-title:hover{
    color: #FD102A;
}

.card--pricing .list-unstyled li{
    text-align: center;
}

.product{
    margin-top: -145px;
    z-index: 11;
    position: relative;
}
.product-card__content{
    position: absolute;
    bottom: 1px;
    left: 2%;
    width: 96%;
    height: 96%;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    font-size: 1vw;
}
.product-card{
   padding-top: 100%;
   border-radius: 100%;
   background: rgba(0,0,0,0.3);
   position: relative;
}
.product-card__img{
    height: 85%;
}
.product-card__img img{
    width: 100%;
}
.product-card__name{
    background: rgba(253, 16, 42, 0.8);
    color: #fff;
    width: 150%;
    line-height: 1.5em;
    margin-left: -25%;
    margin-top: -10%;
    z-index: 1111;
    position: relative;
    font-size: 1em;
    padding: 0.5em 30%;
    text-transform: uppercase;
}

.tab_product{
    display: table;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    position: relative;
    margin: 0px auto 40px;
}
.tab_product span{
    display: inline-block;
    color: #333;
    padding: 5px 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    font-size: 20px;
    font-family: 'Play', sans-serif;
    font-weight: normal;
    color:#FD102A;
    transition:all 0.2s linear;
}
.tab_product span.is-active{
    color: #fff;
    position: relative;
}
.tab_product:after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: 0;
    background: #FD102A;
    height: calc(100% + 6px);
    transition: all 0.2s linear;
    border-radius: 3px;
}
.card{
    padding: 20px 20px 0px;
    margin-bottom: 0px;
}
.card--news{
    position: relative;
    overflow: hidden;
}
.card__image a{
    display: block;
    text-align: center;
}
.card--news >a{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    
    z-index: 111;
    top: 0;
    left: 0;
    transition:all 0.2s linear;
}
@media (min-width: 1199px) {
    .card--news >a{
        top: -100%;
        background: rgba(0,0,0, 0.2);
    }
    .card--news:hover >a{
        top: 0;
    }
}
.card .card-title{
    color: #555;
    line-height: 20px;
    height: auto;
    font-weight: normal;
    padding: 10px 0;
}
.card .card-title >a{
    color: #555;
    text-transform: uppercase;
    font-size: 14px;
}
.card .card-title >a:hover {
    color: #FD102A;
}
.card .price {
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.card .price .price-discount {
  font-size: 15px;
  color: #FD102A;
}
.card .price .price-discount:before{
    content: "\f02c";
    font-family: 'FontAwesome';
    font-size: 13px;
    margin-right: 3px;
    color: #999999;
}
.card .price .price-market {
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-left: 7px;
}
.product-section{
    margin-bottom: 35px;
}
.product-section >a{
    float: right;
    font-size: 11px;
    color: rgba(35,70,113,0.9);
    margin-right: 20px;
    transform: translateY(15px);
    text-transform: uppercase;
}
.product-section >a >i{
    color: #333;
    opacity: 0.5;
    font-size: 12px;
}
.product-section >p{
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    text-transform: uppercase;
/*    margin-left: 20px;
    margin-right: 20px;*/
}
.product-section >p >span i{
    color: #FD102A;
    font-size: 15px;
    margin-right: 5px;
}
.product-section >p >span{
    display: table;
    position: relative;
    background: #fff;
    margin-bottom: -13px;
    padding: 2px 10px;
    margin-left: 20px;
    font-size: 16px;
    text-transform: uppercase;
}
/******SERVICE******/
.service-section{
    background: rgba(238,238,238,0.4);
}
.service-section__description{
    padding: 20px 20px 20px 36px;
    position: relative;
}
.service-desc_short:after{
    content: '';
    display: block;
    width: 3px;
    height: 90px;
    bottom: -2px;
    border-left: 1px solid #FD102A;
    border-right: 1px solid #FD102A;
    opacity: 0.5;
    position: absolute;
    left: 30px;
}
.service-section__description >div:nth-child(2){
    padding: 20px 30px;
    background: #fff;
    font-style: italic;
    font-size: 14px;
    color: #555;
}
.service-section__description >div:nth-child(2):before{
    content: "\f10d";
    font: normal normal normal 10px/1 FontAwesome;
    margin-right: 7px;
    color: #999999;
}
.service-section__description >div:nth-child(2):after{
    content: "\f10e";
    font: normal normal normal 10px/1 FontAwesome;
    margin-right: 7px;
    color: #999999;
}
.service-section__description .mp-title{
    margin-bottom: 0px;
    text-align: left;
    margin-left: 30px;
}
.service-section__description .mp-title::after{
    margin:5px 36px 25px;
}
.service-desc_short:before{
    content: '';
    display: block;
    width: 120px;
    height: 3px;
    bottom: 13px;
    border-bottom: 1px solid #FD102A;
    opacity: 0.5;
    position: absolute;
    left: 15px;
}
.service-section__list .card--products{
    padding: 5px;
    margin: 0 5px;
    background: none;
}
.service-section__list .card--products .card__content .card-title{
    height: auto;
    line-height: 20px;
}
.service-section__list .card--products .card__content h4{
    color: #000;
}
.service-section__list .card--products .card__content p{
    font-size: 14px;
    color: #555;
    padding: 20px;
}
.service-section__description .hd-logo__name{
    float: right;
    margin-top: 20px;
}
.service-section__description .hd-logo__name p:first-child{
    font-size: 22px;
}
.service-section__description .hd-logo__name p{
    font-size: 10px;
}
.video{
    box-shadow: 0px 10px 10px #ccc;
    padding: 15px;
    border: 1px solid #eee;
    /*height: 477px;*/
}
.video >div >p{
    text-transform: uppercase;
    font-size: 15px;
}
.slick-prev{
    left: 10px;
}
.slick-next{
    right: 10px;
}
.image-item{
    width: 20%;
    padding: 20px;
    float: left;
}
.footer-logo{
    padding-top: 40px;
}

/**/
#page{
    padding: 20px 0px;
    background: rgba(0,0,0,0.02);
}
#sidebar .content >div >p{
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #FD102A;
    text-transform: uppercase;
}
#sidebar >div >.title{
    display: block;
    padding: 10px 15px 5px;
    font-size: 17px;
    font-weight: normal;
    border-top: 10px solid #ccc;
    background: #FD102A;
    border-bottom: 1px solid #ccc;
    text-transform: uppercase;
    color: #fff;
}
#sidebar .content{
    border: 1px solid rgba(35,70,113,0.2);
    background-color: rgba(35, 70, 113,0.2);
    box-shadow: 2px -1px 5px rgba(35,70,113,0.4);
    padding-bottom: 20px;
}
#sidebar .item{
    margin: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-bottom: 10px;
}
#sidebar .item:last-child{
    margin-bottom: 0px;
}
#sidebar .item .detail .title{
    font-size: 14px;
    font-weight: normal;
}
#sidebar .item .detail .title:hover{
    color: #FD102A !important;
}
#sidebar .item .detail p{
    font-size: 11px;
    color: #555;
}
#page #product .title{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 26px;
    font-family: arial;
    margin-top: 20px;
}
#page #product{
    padding-bottom: 30px;
    background: #fff;
}
#page .pagination{
    padding-right: 20px;
    margin-top: 30px;
}
#page #product .tab{
    font-weight: normal;
    border-top: 1px solid #ccc;
    background: none !important;
    border-bottom: 1px solid #ccc;
}
.product .item{
    transition:all 0.2s linear;
}
.product .item .item_img{
    padding: 0px;
}
.product .item{
    margin-bottom: 30px;
}
.product .item:hover {
    box-shadow: none;
    transform:translateY(-15px);
}
.product .item:hover .item_detail{
    box-shadow: 0px 20px 20px #ccc;
}
.page-desc{
    font-weight: bold;
    padding: 20px 0px;
}
.share a{
    margin-right: 5px;
}
#page #product .tab{
    border-top: 10px solid #ccc;
    display: block;
    padding: 0px 10px;
    background: rgba(0,0,0,0.4);
    margin: 0px auto 30px;
    padding: 10px 15px;
}
#page #product .tab >div >span{
    color: #555;
}
#page #product .tab >div >span:after{
    color: #f00;
}
.page_news #product .item .detail a{
    display: block;
    font-size: 18px;
    font-family: play;
    color: #000;
    padding: 7px 0px;
}
.page_news #product .item {
    padding: 15px;
    border: 1px solid #eee;
    margin-bottom: 20px;
}
.breadcrumb li > a {
    color: inherit;
    font-weight: normal;
    font-size: 13px;
}
.breadcrumb li:first-child a > i {
    font-size: 17px;
}
.breadcrumb .fa-angle-double-right:before {
    content: "\f101";
    font-size: 10px;
    color: #333;
    opacity: 0.7;
}
.news-section{
    border: 5px solid #fff;
}
#sidebar .content .item .detail{
    padding-left: 7px;
    line-height: 18px;
    padding-top: 5px;
}
#sidebar .card{
    padding: 10px 10px 0px;
}
#sidebar .card .card-title{
    font-size: 13px;
    padding: 5px;
    padding-top: 8px;
    margin-top: 15px;
}
#sidebar .content >ul >li{
    margin-bottom: 10px;
}
#sidebar .content >ul >li >a{
    color: #fff;
    padding: 0px 10px;
    display: block;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #FD102A;
    position: relative;
    border-top: 10px solid transparent;
    text-transform: uppercase;
    font-size: 15px;
}
#sidebar .content >ul >li >a >span{
    transform: translateY(2px);
    display: block;
}
#sidebar .content >ul >li:nth-child(2n){
   background: rgba(35, 70, 113,0.2);
}
#sidebar .content >ul >li:nth-child(2n) >a{
   color: #333; 
}
#sidebar .content >ul >li >a:before{
    content: "\f0da";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    left: -13px;
    color: #fff;
}
#sidebar .content >ul >li:nth-child(2n+1) >a{
   background: #c1061a;
}
#sidebar .content >ul >li:nth-child(2n+1) >ul{
   background: rgba(35, 70, 113,0.2); 
}
#sidebar .content >ul >li:nth-child(2n+1) >ul >li{
    margin-top: 1px;
}
#sidebar .content >ul >li:nth-child(2n+1) >ul >li >a{
    padding: 10px;
    background: rgba(35, 70, 113,0.2);
    color: #333;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    padding-left: 12px;
}
#sidebar .content >ul >li:nth-child(2n+1) >ul >li >a:hover{
    background: #c1061a;
    color: #fff;
}
#sidebar .content >ul >li:nth-child(2n+1) >ul >li >a:hover:before{
    color: #FD102A;
}
#sidebar .content >ul >li:nth-child(2n+1) >ul >li >a:before {
    content: "\f1ce";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 8px;
    margin-right: 10px;
    color: #FD102A;
    transform: translateY(-2px);
    display: inline-block;
}
#sidebar >div.wrap-sidebar{
    background: #eee;
    height: 100%;
    /* margin-top: 5px; */
    position: relative;
    /* margin-left: 10px; */
    transform: translateX(-5px) translateY(5px);
}
#sidebar .avatar{
    margin: 0 auto;
    padding-top: 17px;
    width: 100px;
    height: 100px;
    background: #F1F3F5;
}
#sidebar .avatar i{
    font-size: 50px;
}
#sidebar .author h1{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    display: table;
    margin: 15px auto;
    color: #FD102A;
}
#sidebar .author h1:after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 8px;
    background: #FD102A;
}
#sidebar .card--rounded .card__image{
    margin-top: 0px;
}
#sidebar .card--profile {
    border-radius: 0px;
    margin-top: 90px;
}
.card--profile .card__content .avatar{
    border: 5px solid #91A2B8;
}
.card--profile ul li a{
    padding: 12px 0px 7px;
    color: #333;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0px 12px;
    text-align: left;
    border-bottom: 1px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}
.card--profile ul li a:hover{
    color: #FD102A;
}
.card--profile ul li a:hover:before{
    color: #FD102A;
}
.card--profile ul li a:before {
    content: "\f1ce";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 8px;
    margin-right: 10px;
    color: #FD102A;
    transform: translateY(-2px);
    display: inline-block;
}
@media (min-width: 1025px) {
    .box-padding {
        padding: 30px 0px 30px 50px;
        background: #fff
    }
}
.product-item {
    padding: 20px 0;
}
.product-item .card__image .wrap-img a {
    position: relative;
}
.product-item .card__image .wrap-img a::after, .product-item .card__image .wrap-img a::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.product-item:hover .card__image .wrap-img a::before {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    opacity: 1;
}
.product-item:hover .card__image .wrap-img a::after {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    opacity: 1;
}
.s-footer__backtop a {
  color: #FFFFFF;
}
.s-footer__backtop a:first-child {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 111111;
  width: 40px;
  height: 40px;
  line-height: 44px;
  background-color: #fd102a;
  border-radius: 50%;
  text-align: center;
}
.s-footer__backtop a:first-child i {
  font-size: 20px;
}
.s-footer__backtop a:first-child span {
  background-color: #fd102a;
  margin-left: 0;
  line-height: 1;
  padding: 5px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  left: calc(100% - 5px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.s-footer__backtop a:last-child {
  position: fixed;
  z-index: 111111;
  font-size: 20px;
  background-color: #fd102a;
  bottom: calc(60% - 1em);
  opacity: 0;
  right: 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.s-footer__backtop a:last-child.is-show {
  opacity: 1;
  bottom: 15px;
}

/* c-animate-loopFlicker */
.c-animate-loopFlicker {
  -webkit-animation: loopFlicker 1s infinite;
  -o-animation: loopFlicker 1s infinite;
  animation: loopFlicker 1s infinite;
}

@-webkit-keyframes loopFlicker {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes loopFlicker {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes loopFlicker {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes loopFlicker {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
/* About us */

.cf-introduce .cf-introduce__content {
    display: table-cell;
    vertical-align: middle;
}

.cf-introduce .cf-introduce__content fieldset {
    border: 1px solid #CCC;
    padding: 10px 15px 30px;
}

.cf-introduce .cf-introduce__content fieldset legend {
    text-align: left;
    padding: 5px 15px;
    font-size: 22px;
    font-weight: bold;
    color: #0B7741;
}

.cf-introduce .cf-introduce__content fieldset legend img {
    height: 40px;
}

.cf-introduce .cf-introduce__content fieldset div {
    color: #888;
    font-size: 16px;
}

.cf-introduce .cf-introduce {
    display: table !important;
}
.cf-introduce .cf-introduce__img {
    display: table-cell;
    line-height: 0;
    width: 35%;
    border: 7px solid #FAFAFA;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

}
.cf-introduce .cf-introduce__content {
    display: table-cell;
    padding: 0 20px 20px;
    background-color: #F9FEFB;
    box-shadow: inset 0px 1px 12px #EEEEEE;
}
.cf-introduce .cf-introduce__content fieldset {
    padding: 10px 30px 30px;
}
.btnbuy{
    width: 150px;
    cursor: pointer;
    margin: 10px auto 0;
}
.btnbuy:hover>span:first-child{
    background: #FD102A!important;
}
.btnbuy:hover>span:last-child{
    background: rgba(35, 70, 113,0.2);
    border: 1px solid rgba(35, 70, 113,0.3);
}
.btnbuy >span:first-child{
    width: 30px;
    line-height: 30px;
    float: left;
    color:#fff;
    text-align: center;
    
}
.btnbuy >span:last-child{
    width: calc(100% - 30px);
    line-height: 28px;
    float: left;
    background: rgba(246, 145, 37,0.1);
    text-align: center;
    font-size: 14px;
    border: 1px solid rgba(246, 145, 37,0.3);
    color: #000;
    text-transform: uppercase;
}
@media (max-width: 991px) {
    .cf-introduce .cf-introduce__img {
        display: none;
    }
}
@media (max-width: 480px) {
    .row-block {
        display: block;
    }
    .card--rounded .card__image {
        display: -ms-flex;
         display: -webkit-box; 
        display: -ms-flexbox;
         display: flex; 
        align-items: center;
    }
    .card__image .wrap-img {
        width: 100%;
        margin-right: 10px;
    }
    .cf-content {
        width: 100%;
    }
    .card .card-title {
        padding-top: 0;
    }
}