@charset "UTF-8";
:root {
  font-size: 10px;
}

body {
  font-family: "Arial", "Helvetica", "Arial", sans-serif;
  line-height: 1.53061225;
  color: #222222;
  font-size: 1.4rem;
}

.ht-container::after, .row::after, .form-group::after, .uploader.uploader-file::after, .menu::after, .menu__collapse::after, .menu__item::after, .tabs-list::after, .card::after, .card--products .card-price::after, .star::after, .comment::after, .comment__footer::after, .sidebar-multicates--style-01 ul ul li a::after {
  content: "";
  display: block;
  clear: both;
}

/* 
* position
*/
.centered, .ht-container-full, .ht-container, .modal-box {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

/* 
* Độ rõ của ảnh
*/
.bg--default {
  background-color: #222222 !important;
}
.bg--primary {
  background-color: #FD102A !important;
}
.bg--secondary {
  background-color: #FD102A!important;
}
.bg--success {
  background-color: #55b559 !important;
}
.bg--error {
  background-color: #f55145 !important;
}
.bg--warning {
  background-color: #ff9e0f !important;
}
.bg--info {
  background-color: #00cae3 !important;
}
.bg--muted {
  background-color: #909090 !important;
}
.bg--white {
  background-color: #FFFFFF !important;
}
.bg--icons {
  background-color: #999999 !important;
}

.border--default {
  border-color: #222222 !important;
}
.border--primary {
  border-color: #FD102A !important;
}
.border--secondary {
  border-color: #FD102A!important;
}
.border--success {
  border-color: #55b559 !important;
}
.border--error {
  border-color: #f55145 !important;
}
.border--warning {
  border-color: #ff9e0f !important;
}
.border--info {
  border-color: #00cae3 !important;
}
.border--muted {
  border-color: #909090 !important;
}
.border--white {
  border-color: #FFFFFF !important;
}
.border--icons {
  border-color: #999999 !important;
}

.red {
  color: #F00;
}

.section {
  padding: 30px 0;
  position: relative;
}

[class^="section"] {
  background-position: center center;
  background-size: cover;
}

.section-image {
  position: relative;
}
.section-image::after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
}
.section-image .ht-container {
  position: relative;
  z-index: 2;
}

.section-image--fixed {
  background-attachment: fixed;
}

.overlay, img, .title {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

i + span, span + i, i + a, a + i {
  margin-left: 0.5em;
}

span + span.icons {
  height: auto;
  width: auto;
  line-height: normal;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
}

.overlay .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.5;
}

.overlay .btn:hover {
  opacity: 1;
}

.content-center, .gallery-zoom .slick-slide {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.page-title {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 9px;
  margin-bottom: 20px !important;
}
.page-title > i {
  position: relative;
}
.page-title > i::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #FD102A;
  left: 0;
  bottom: -10px;
}

.hover a:hover {
  color: #FD102A;
}

/* Main Container. */
.ht-container-full {
  width: 100%;
}
@media (min-width: 1px) and (max-width: 1200px) {
  .ht-container-full {
    width: 98%;
  }
}

.ht-container {
  width: 100%;
  max-width: 1200px;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .ht-container {
    max-width: 1024px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ht-container {
    max-width: 768px;
  }
}
@media (min-width: 1px) and (max-width: 1024px) {
  .ht-container {
    width: 98%;
  }
}

@media (min-width: 1025px) {
  [class^="col-"] {
    float: left;
  }
  [class^="col-"] + [class^="col-"] {
    margin-left: 1.7667844523%;
  }
}

@media (min-width: 1025px) {
  .offset-right {
    float: right !important;
  }

  .col-1 {
    width: 6.7137809187%;
  }

  .offset-1 {
    margin-left: 10.2473498233% !important;
  }

  .offset-right-1 {
    float: right;
    margin-right: 8.480565371% !important;
    margin-left: 0px !important;
  }

  .col-2 {
    width: 15.1943462898%;
  }
  .col-2-4 {
        width: 18.5865724382%;
    }
  .offset-2 {
    margin-left: 18.7279151943% !important;
  }

  .offset-right-2 {
    float: right;
    margin-right: 16.961130742% !important;
    margin-left: 0px !important;
  }

  .col-3 {
    width: 23.6749116608%;
  }

  .offset-3 {
    margin-left: 27.2084805654% !important;
  }

  .offset-right-3 {
    float: right;
    margin-right: 25.4416961131% !important;
    margin-left: 0px !important;
  }

  .col-4 {
    width: 32.1554770318%;
  }

  .offset-4 {
    margin-left: 35.6890459364% !important;
  }

  .offset-right-4 {
    float: right;
    margin-right: 33.9222614841% !important;
    margin-left: 0px !important;
  }

  .col-5 {
    width: 40.6360424028%;
  }

  .offset-5 {
    margin-left: 44.1696113074% !important;
  }

  .offset-right-5 {
    float: right;
    margin-right: 42.4028268551% !important;
    margin-left: 0px !important;
  }

  .col-6 {
    width: 49.1166077739%;
  }

  .offset-6 {
    margin-left: 52.6501766784% !important;
  }

  .offset-right-6 {
    float: right;
    margin-right: 50.8833922261% !important;
    margin-left: 0px !important;
  }

  .col-7 {
    width: 57.5971731449%;
  }

  .offset-7 {
    margin-left: 61.1307420495% !important;
  }

  .offset-right-7 {
    float: right;
    margin-right: 59.3639575972% !important;
    margin-left: 0px !important;
  }

  .col-8 {
    width: 66.0777385159%;
  }

  .offset-8 {
    margin-left: 69.6113074205% !important;
  }

  .offset-right-8 {
    float: right;
    margin-right: 67.8445229682% !important;
    margin-left: 0px !important;
  }

  .col-9 {
    width: 74.5583038869%;
  }

  .offset-9 {
    margin-left: 78.0918727915% !important;
  }

  .offset-right-9 {
    float: right;
    margin-right: 76.3250883392% !important;
    margin-left: 0px !important;
  }

  .col-10 {
    width: 83.038869258%;
  }

  .offset-10 {
    margin-left: 86.5724381625% !important;
  }

  .offset-right-10 {
    float: right;
    margin-right: 84.8056537102% !important;
    margin-left: 0px !important;
  }

  .col-11 {
    width: 91.519434629%;
  }

  .offset-11 {
    margin-left: 95.0530035336% !important;
  }

  .offset-right-11 {
    float: right;
    margin-right: 93.2862190813% !important;
    margin-left: 0px !important;
  }

  .col-12 {
    width: 100%;
  }

  .offset-12 {
    margin-left: 103.5335689046% !important;
  }

  .offset-right-12 {
    float: right;
    margin-right: 101.7667844523% !important;
    margin-left: 0px !important;
  }

  .col-12 + .col-12:nth-of-type(1n+1) {
    margin-left: 0;
  }

   .col-6 + .col-6:nth-of-type(2n+1) {
    margin-left: 0;
  }

   .col-4 + .col-4:nth-of-type(3n+1) {
    margin-left: 0;
  }

   .col-3 + .col-3:nth-of-type(4n+1) {
    margin-left: 0;
  }
  .col-2 + .col-2:nth-of-type(6n+1) {
    margin-left: 0;
  }
  .col-2-4 + .col-2-4:nth-of-type(5n+1) {
        margin-left: 0;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .col-md-1 {
    width: 6.7137809187%;
    float: left;
  }
  .col-md-1 + .col-md-1 {
    margin-left: 1.7667844523%;
  }

  .col-md-2 {
    width: 15.1943462898%;
    float: left;
  }
  .col-md-2 + .col-md-2 {
    margin-left: 1.7667844523%;
  }

  .col-md-3 {
    width: 23.6749116608%;
    float: left;
  }
  .col-md-3 + .col-md-3 {
    margin-left: 1.7667844523%;
  }

  .col-md-4 {
    width: 32.1554770318%;
    float: left;
  }
  .col-md-4 + .col-md-4 {
    margin-left: 1.7667844523%;
  }

  .col-md-5 {
    width: 40.6360424028%;
    float: left;
  }
  .col-md-5 + .col-md-5 {
    margin-left: 1.7667844523%;
  }

  .col-md-6 {
    width: 49.1166077739%;
    float: left;
  }
  .col-md-6 + .col-md-6 {
    margin-left: 1.7667844523%;
  }

  .col-md-7 {
    width: 57.5971731449%;
    float: left;
  }
  .col-md-7 + .col-md-7 {
    margin-left: 1.7667844523%;
  }

  .col-md-8 {
    width: 66.0777385159%;
    float: left;
  }
  .col-md-8 + .col-md-8 {
    margin-left: 1.7667844523%;
  }

  .col-md-9 {
    width: 74.5583038869%;
    float: left;
  }
  .col-md-9 + .col-md-9 {
    margin-left: 1.7667844523%;
  }

  .col-md-10 {
    width: 83.038869258%;
    float: left;
  }
  .col-md-10 + .col-md-10 {
    margin-left: 1.7667844523%;
  }

  .col-md-11 {
    width: 91.519434629%;
    float: left;
  }
  .col-md-11 + .col-md-11 {
    margin-left: 1.7667844523%;
  }

  .col-md-12 {
    width: 100%;
    float: left;
  }
  .col-md-12 + .col-md-12 {
    margin-left: 1.7667844523%;
  }

  .col-md-6 + .col-md-6:nth-of-type(2n+1) {
    margin-left: 0;
  }

  .col-md-4 + .col-md-4:nth-of-type(3n+1) {
    margin-left: 0;
  }

  .col-md-3 + .col-md-3:nth-of-type(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .col-xs-6 {
    width: 49.1166077739%;
    float: left;
  }
  .col-xs-6 + .col-xs-6 {
    margin-left: 1.7667844523%;
  }

  .col-xs-6 + .col-xs-6:nth-of-type(2n+1) {
    margin-left: 0;
  }

  .col-xs-4 + .col-xs-4:nth-of-type(3n+1) {
    margin-left: 0;
  }

  .col-xs-3 + .col-xs-3:nth-of-type(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .col-sm-1 {
    width: 6.7137809187%;
    float: left;
  }
  .col-sm-1 + .col-sm-1 {
    margin-left: 1.7667844523%;
  }

  .col-sm-2 {
    width: 15.1943462898%;
    float: left;
  }
  .col-sm-2 + .col-sm-2 {
    margin-left: 1.7667844523%;
  }

  .col-sm-3 {
    width: 23.6749116608%;
    float: left;
  }
  .col-sm-3 + .col-sm-3 {
    margin-left: 1.7667844523%;
  }

  .col-sm-4 {
    width: 32.1554770318%;
    float: left;
  }
  .col-sm-4 + .col-sm-4 {
    margin-left: 1.7667844523%;
  }

  .col-sm-5 {
    width: 40.6360424028%;
    float: left;
  }
  .col-sm-5 + .col-sm-5 {
    margin-left: 1.7667844523%;
  }

  .col-sm-6 {
    width: 49.1166077739%;
    float: left;
  }
  .col-sm-6 + .col-sm-6 {
    margin-left: 1.7667844523%;
  }

  .col-sm-7 {
    width: 57.5971731449%;
    float: left;
  }
  .col-sm-7 + .col-sm-7 {
    margin-left: 1.7667844523%;
  }

  .col-sm-8 {
    width: 66.0777385159%;
    float: left;
  }
  .col-sm-8 + .col-sm-8 {
    margin-left: 1.7667844523%;
  }

  .col-sm-9 {
    width: 74.5583038869%;
    float: left;
  }
  .col-sm-9 + .col-sm-9 {
    margin-left: 1.7667844523%;
  }

  .col-sm-10 {
    width: 83.038869258%;
    float: left;
  }
  .col-sm-10 + .col-sm-10 {
    margin-left: 1.7667844523%;
  }

  .col-sm-11 {
    width: 91.519434629%;
    float: left;
  }
  .col-sm-11 + .col-sm-11 {
    margin-left: 1.7667844523%;
  }

  .col-sm-12 {
    width: 100%;
    float: left;
  }
  .col-sm-12 + .col-sm-12 {
    margin-left: 1.7667844523%;
  }

  .col-sm-6 + .col-sm-6:nth-of-type(2n+1) {
    margin-left: 0;
  }

  .col-sm-4 + .col-sm-4:nth-of-type(3n+1) {
    margin-left: 0;
  }

  .col-sm-3 + .col-sm-3:nth-of-type(4n+1) {
    margin-left: 0;
  }

  .sm-center {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto;
  }
}
@media (max-width: 768px) {
  .hiden-sm {
    display: none;
  }
}

@media (max-width: 1024px) {
  .hiden-md {
    display: none;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .h1-lg, .h2-lg, .h3-lg, .h4-lg, .h5-lg, .h6-lg {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin: 10px 0;
}

.h1-lg, .h2-lg, .h3-lg {
  margin-top: 20px;
  margin-bottom: 10px;
}

small, .small {
  font-size: 80%;
}

h1, .h1 {
  font-size: 2.0rem;
}

h2, .h2 {
  font-size: 1.8rem;
}

h3, .h3 {
  font-size: 1.6rem;
}

h4, .h4 {
  font-size: 1.4rem;
}

h5, .h5 {
  font-size: 1.3rem;
}

h6, .h6 {
  font-size: 1.1rem;
}

.h1-lg {
  font-size: 3.6rem;
}

.h2-lg {
  font-size: 3rem;
}

.h3-lg {
  font-size: 2.4rem;
}

.h4-lg {
  font-size: 1.8rem;
}

.h5-lg {
  font-size: 1.4rem;
}

.h6-lg {
  font-size: 1.2rem;
}

.h1-lg .small, .h1-lg small, .h2-lg .small, .h2-lg small, .h3-lg .small, .h3-lg small {
  font-size: 65%;
}

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, .h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small, h5 .small, h5 small, h6 .small, h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

blockquote {
  font-size: 1.75rem;
  padding: 0.5714285714em 1.1428571429em 0.5714285714em 1.1428571429em;
  margin: 0em 0em 1.1428571429em 0em;
  border-left: 0.2857142857em solid #EEEEEE;
}

blockquote p {
  font-style: italic;
}

blockquote small, blockquote .small {
  color: #777;
}

blockquote small::before, blockquote .small::before {
  content: '\2014 \00A0';
}

.title {
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 600;
}
.title a {
  text-decoration: none;
  color: #222222;
}
.title a:hover {
  color: #ff6701;
}

.title-gradient {
  position: relative;
  padding: 15px 0;
  display: inline-block;
}
.title-gradient::after {
  content: '';
  position: absolute;
  bottom: 7px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 0, 0, 0);
  background-image: -webkit-linear-gradient(left, rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
  background-image: linear-gradient(to right,rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
}

.text--muted {
  color: #909090;
}
.text--default {
  color: #222222;
}
.text--primary {
  color: #FD102A;
}
.text--secondary {
  color: #FD102A;
}
.text--success {
  color: #55b559;
}
.text--error {
  color: #f55145;
}
.text--warning {
  color: #ff9e0f;
}
.text--info {
  color: #00cae3;
}
.text--muted {
  color: #909090;
}
.text--white {
  color: #FFFFFF;
}
.text--icons {
  color: #999999;
}
.text-center {
  text-align: center !important;
}
.text--hypertext {
  color: blue !important;
}
.text--hypertext:hover {
  color: #FD102A;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-uppercase {
  text-transform: uppercase;
  font-family: "Open Sans", "Helvetica", sans-serif;
}
.text-uppercase-none {
  text-transform: none !important;
}

.text--transform-none {
  text-transform: none !important;
}

.btn, button, [class^="btn"] {
  display: inline-block;
  font-size: 1.4rem;
  padding: 0.7142857143em 1.4285714286em 0.7142857143em 1.4285714286em;
  cursor: pointer;
  border: none;
  border-radius: 0.3em;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0;
  line-height: 1;
  background-color: #999999;
  box-shadow: 0 2px 2px 0 rgba(34, 34, 34, 0.14), 0 3px 1px -2px rgba(34, 34, 34, 0.2), 0 1px 5px 0 rgba(34, 34, 34, 0.12);
  color: #FFFFFF;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btn:active, button:active, [class^="btn"]:active, .btn:hover, button:hover, [class^="btn"]:hover, .btn:focus, button:focus, [class^="btn"]:focus {
  box-shadow: 0 14px 26px -12px rgba(34, 34, 34, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(34, 34, 34, 0.2);
  outline: none;
}

.btn--primary {
  box-shadow: 0 2px 2px 0 rgba(25, 118, 210, 0.14), 0 3px 1px -2px rgba(25, 118, 210, 0.2), 0 1px 5px 0 rgba(25, 118, 210, 0.12);
}
.btn--primary, .btn--primary:hover, .btn--primary:focus, .btn--primary:active, .btn--primary.active, .btn--primary:active:focus, .btn--primary:active:hover, .btn--primary.active:focus, .btn--primary.active:hover {
  background-color: #FD102A;
}
.btn--primary:focus, .btn--primary:active, .btn--primary:hover {
  box-shadow: 0 14px 26px -12px rgba(25, 118, 210, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(25, 118, 210, 0.2);
}
.btn--secondary {
  box-shadow: 0 2px 2px 0 rgba(255, 102, 0, 0.14), 0 3px 1px -2px rgba(255, 102, 0, 0.2), 0 1px 5px 0 rgba(255, 102, 0, 0.12);
}
.btn--secondary, .btn--secondary:hover, .btn--secondary:focus, .btn--secondary:active, .btn--secondary.active, .btn--secondary:active:focus, .btn--secondary:active:hover, .btn--secondary.active:focus, .btn--secondary.active:hover {
  background-color: #FD102A;
}
.btn--secondary:focus, .btn--secondary:active, .btn--secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 102, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 102, 0, 0.2);
}
.btn--info {
  box-shadow: 0 2px 2px 0 rgba(0, 202, 227, 0.14), 0 3px 1px -2px rgba(0, 202, 227, 0.2), 0 1px 5px 0 rgba(0, 202, 227, 0.12);
}
.btn--info, .btn--info:hover, .btn--info:focus, .btn--info:active, .btn--info.active, .btn--info:active:focus, .btn--info:active:hover, .btn--info.active:focus, .btn--info.active:hover {
  background-color: #00cae3;
}
.btn--info:focus, .btn--info:active, .btn--info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 202, 227, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 202, 227, 0.2);
}
.btn--success {
  box-shadow: 0 2px 2px 0 rgba(85, 181, 89, 0.14), 0 3px 1px -2px rgba(85, 181, 89, 0.2), 0 1px 5px 0 rgba(85, 181, 89, 0.12);
}
.btn--success, .btn--success:hover, .btn--success:focus, .btn--success:active, .btn--success.active, .btn--success:active:focus, .btn--success:active:hover, .btn--success.active:focus, .btn--success.active:hover {
  background-color: #55b559;
}
.btn--success:focus, .btn--success:active, .btn--success:hover {
  box-shadow: 0 14px 26px -12px rgba(85, 181, 89, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 181, 89, 0.2);
}
.btn--warning {
  box-shadow: 0 2px 2px 0 rgba(255, 158, 15, 0.14), 0 3px 1px -2px rgba(255, 158, 15, 0.2), 0 1px 5px 0 rgba(255, 158, 15, 0.12);
}
.btn--warning, .btn--warning:hover, .btn--warning:focus, .btn--warning:active, .btn--warning.active, .btn--warning:active:focus, .btn--warning:active:hover, .btn--warning.active:focus, .btn--warning.active:hover {
  background-color: #ff9e0f;
}
.btn--warning:focus, .btn--warning:active, .btn--warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 158, 15, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 158, 15, 0.2);
}
.btn--error {
  box-shadow: 0 2px 2px 0 rgba(245, 81, 69, 0.14), 0 3px 1px -2px rgba(245, 81, 69, 0.2), 0 1px 5px 0 rgba(245, 81, 69, 0.12);
}
.btn--error, .btn--error:hover, .btn--error:focus, .btn--error:active, .btn--error.active, .btn--error:active:focus, .btn--error:active:hover, .btn--error.active:focus, .btn--error.active:hover {
  background-color: #f55145;
}
.btn--error:focus, .btn--error:active, .btn--error:hover {
  box-shadow: 0 14px 26px -12px rgba(245, 81, 69, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(245, 81, 69, 0.2);
}
.btn--white {
  box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.14), 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.12);
  color: #222222;
}
.btn--white, .btn--white:hover, .btn--white:focus, .btn--white:active, .btn--white.active, .btn--white:active:focus, .btn--white:active:hover, .btn--white.active:focus, .btn--white.active:hover {
  background-color: #FFFFFF;
}
.btn--white:focus, .btn--white:active, .btn--white:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 255, 255, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}
.btn--border {
  box-shadow: none;
  border: 1px solid #FFFFFF;
  background-color: transparent;
}
.btn--simple {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  color: #333333;
}
.btn--xs {
  font-size: 0.9rem;
}
.btn--sm {
  font-size: 1.2rem;
}
.btn--lg {
  font-size: 1.8rem;
}
.btn--round {
  border-radius: 3em;
}
.btn--circle {
  border-radius: 50%;
  padding: 0;
  width: 2.4285714286em;
  height: 2.4285714286em;
  text-align: center;
  line-height: 2.4285714286em;
}
.btn__icon--facebook {
  box-shadow: 0 2px 2px 0 rgba(54, 88, 153, 0.14), 0 3px 1px -2px rgba(54, 88, 153, 0.2), 0 1px 5px 0 rgba(54, 88, 153, 0.12);
}
.btn__icon--facebook, .btn__icon--facebook:hover, .btn__icon--facebook:focus, .btn__icon--facebook:active, .btn__icon--facebook.active, .btn__icon--facebook:active:focus, .btn__icon--facebook:active:hover, .btn__icon--facebook.active:focus, .btn__icon--facebook.active:hover {
  background-color: #365899;
}
.btn__icon--facebook:focus, .btn__icon--facebook:active, .btn__icon--facebook:hover {
  box-shadow: 0 14px 26px -12px rgba(54, 88, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(54, 88, 153, 0.2);
}
.btn__icon--facebook.btn--simple {
  color: #365899;
}
.btn__icon--twitter {
  background-color: #55acee;
}
.btn__icon--twitter.btn--simple {
  color: #55acee;
}
.btn__icon--googleplus {
  background-color: #dd4b39;
}
.btn__icon--googleplus.btn--simple {
  color: #dd4b39;
}
.btn__icon--instagram {
  background-color: #cc2127;
}
.btn__icon--instagram.btn--simple {
  color: #cc2127;
}
.btn__icon--youtube {
  background-color: #e52d27;
}
.btn__icon--youtube.btn--simple {
  color: #e52d27;
}
.btn__icon--linkedin {
  background-color: #0976b4;
}
.btn__icon--linkedin.btn--simple {
  color: #0976b4;
}

[class^="btn__icon"].btn--circle, [class^="btn__icon"].btn--rounded {
  padding: 0;
  width: 2.4285714286em;
  height: 2.4285714286em;
  text-align: center;
  line-height: 2.4285714286em;
}
[class^="btn__icon"].btn--simple {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
[class^="btn__icon"].btn--simple:hover {
  background-color: transparent;
  opacity: 0.7;
}

.form__input {
  font-size: 1.4rem;
  height: 2.5714285714em;
  width: 100%;
  padding: 0.5em 0em 0.5em 0em;
  line-height: 1.53061225;
  border: 0;
  background-image: linear-gradient(#FD102A, #FD102A), linear-gradient(#CCCCCC, #CCCCCC);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  -webkit-transition: background 0s ease-out;
  -o-transition: background 0s ease-out;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400;
}
.form__input::-webkit-input-placeholder {
  color: inherit;
  font-style: italic;
}
.form__input::-moz-placeholder {
  color: inherit;
  font-style: italic;
}
.form__input:-moz-placeholder {
  color: inherit;
  font-style: italic;
}
.form__input:-ms-input-placeholder {
  color: #333333;
  font-style: italic;
}

.form__input:focus {
  outline: none;
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}

.form-group {
  position: relative;
  margin-bottom: 1.0714285714em;
}
.form-label .form__label {
  font-size: 1.4rem;
  color: #333333;
  font-style: italic;
  pointer-events: none;
  position: absolute;
  top: 8px;
  left: 0;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.form-label:not(.is-empty) .form__label, .form-label.is-focused .form__label {
  font-size: 1.1rem;
  position: absolute;
  top: -6px;
  left: 0;
  color: #FD102A;
}
.form-icon .form__icon {
  font-size: 1.6rem;
  display: inline-block;
  width: 1.25em;
  height: 1.8125em;
  text-align: center;
  overflow: hidden;
  vertical-align: top;
  position: relative;
  top: 6px;
}
.form-icon > .form__input, .form-icon > .dropdownjs, .form-icon > .uploader-file {
  width: calc(100% - 25px);
  display: inline-block;
}
.form-icon.form-checkbox {
  margin-left: 1.7142857143em;
  font-size: 80%;
}
.form__error {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #f55145;
  cursor: pointer;
  display: none;
  line-height: 1;
}
textarea + .form__error {
  top: 5px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.is-error .form__input:focus {
  background-image: linear-gradient(#f55145, #f55145), linear-gradient(#CCCCCC, #CCCCCC);
}
.is-error .form__label {
  color: #f55145 !important;
}
.is-error .form__error {
  display: block;
}

textarea.form__input {
  height: auto;
}

input[type=file] {
  display: block;
}

.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 0.6666666667em;
  margin-bottom: 0.6666666667em;
  line-height: 1;
}

.checkbox label {
  cursor: pointer;
  padding-left: 0;
  color: #666666;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  width: 0em;
  height: 0em;
  overflow: hidden;
  pointer-events: none;
}
.checkbox__tick {
  padding-right: 0.5em;
}
.checkbox__tick::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  width: 1em;
  height: 1em;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(3,3,1);
  -moz-transform: scale3d(3,3,1);
  -o-transform: scale3d(3,3,1);
  -ms-transform: scale3d(3,3,1);
  transform: scale3d(3,3,1);
}
.checkbox__tick.disable-bg::before {
  display: none;
}
.checkbox__tick .check {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid #666666;
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}
.checkbox__tick .check::before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -0.3333333333em;
  margin-left: 0.3333333333em;
  width: 0em;
  height: 0em;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.checkbox input[type=checkbox]:focus + .checkbox__tick .check::after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:checked + .checkbox__tick .check {
  background: #FD102A;
}
.checkbox input[type=checkbox]:checked + .checkbox__tick .check::before {
  color: #FFFFFF;
  box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em -0.8em 0 0.7333333333em;
  -webkit-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.checkbox input[type=checkbox]:checked + .checkbox__tick:before {
  -webkit-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.checkbox input[type=checkbox]:checked + .checkbox__tick .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.checkbox input[type=checkbox]:not(:checked) + .checkbox__tick:before {
  -webkit-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox input[type=checkbox]:not(:checked) + .checkbox__tick .check:after {
  -webkit-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
fieldset[disabled] .checkbox, fieldset[disabled] .checkbox input[type=checkbox],
.checkbox input[type=checkbox][disabled] ~ .checkbox__tick .check,
.checkbox input[type=checkbox][disabled] + .circle {
  opacity: 0.5;
}
.checkbox input[type=checkbox][disabled] ~ .checkbox__tick .check {
  border-color: #000000;
  opacity: .26;
}
.checkbox input[type=checkbox][disabled] + .checkbox__tick .check:after {
  background-color: #FD102A;
  transform: rotate(-45deg);
}
@-webkit-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1em 0.1333333333em 0 0.7333333333em;
  }
  50% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em 0.1333333333em 0 0.7333333333em;
  }
  100% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em -0.8em 0 0.7333333333em;
  }
}
@-moz-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1em 0.1333333333em 0 0.7333333333em;
  }
  50% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em 0.1333333333em 0 0.7333333333em;
  }
  100% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em -0.8em 0 0.7333333333em;
  }
}
@-o-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1em 0.1333333333em 0 0.7333333333em;
  }
  50% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em 0.1333333333em 0 0.7333333333em;
  }
  100% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em -0.8em 0 0.7333333333em;
  }
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1em 0.1333333333em 0 0.7333333333em;
  }
  50% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em 0.1333333333em 0 0.7333333333em;
  }
  100% {
    box-shadow: 0 0 0 0.6666666667em, 0.6666666667em -0.6666666667em 0 0.6666666667em, 2.1333333333em 0 0 1.3333333333em, 0px 2.1333333333em 0 1.3333333333em, -0.3333333333em 0.3333333333em 0 0.6666666667em, 1.3333333333em -0.8em 0 0.7333333333em;
  }
}
@-webkit-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.radio label {
  cursor: pointer;
  position: relative;
  color: #666666;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.radio [class^="radio__"] {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
.radio__circle {
  border: 1px solid #666666;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  margin-right: 0.3333333333em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.radio__check {
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: #FD102A;
  -webkit-transform: scale3d(0,0,0);
  -moz-transform: scale3d(0,0,0);
  -o-transform: scale3d(0,0,0);
  -ms-transform: scale3d(0,0,0);
  transform: scale3d(0,0,0);
}
.radio__check:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.84);
  width: 1em;
  height: 1em;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(4,4,1);
  -moz-transform: scale3d(4,4,1);
  -o-transform: scale3d(4,4,1);
  -ms-transform: scale3d(4,4,1);
  transform: scale3d(4,4,1);
}
.radio input[type=radio] {
  opacity: 0;
  width: 0em;
  height: 0em;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.radio input[type=radio]:checked ~ .radio__check, .radio input[type=radio]:checked ~ .radio__circle {
  opacity: 1;
}
.radio input[type=radio]:checked ~ .radio__check {
  background-color: #FD102A;
}
.radio input[type=radio]:checked ~ .radio__circle {
  border-color: #FD102A;
}
.radio input[type=radio]:checked + .radio__check::after {
  -webkit-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.radio input[type=radio]:checked + .radio__circle .radio__check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}
.radio input[type=radio]:checked + .radio__circle .radio__check::after {
  -webkit-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.radio input[type=radio][disabled] ~ .radio__check, .radio input[type=radio][disabled] ~ .radio__circle {
  opacity: 0.26;
}
.radio input[type=radio][disabled] ~ .radio__check {
  background-color: #000000;
}
.radio input[type=radio][disabled] ~ .radio__circle {
  border-color: #000000;
}

@-webkit-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton, .togglebutton label, .togglebutton input, .togglebutton .toggle {
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: #666666;
}
.togglebutton label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
  margin-left: 5px;
}
.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border-radius: 20px;
  position: relative;
  top: -3px;
  left: -5px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  border: 1px solid #666666;
  -webkit-transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
  -o-transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type=checkbox][disabled] + .toggle:after, .togglebutton label input[type=checkbox][disabled]:checked + .toggle:after {
  background-color: #FD102A;
}
.togglebutton label input[type=checkbox] + .toggle:active:after, .togglebutton label input[type=checkbox][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type=checkbox]:checked + .toggle:after {
  left: 15px;
}
.togglebutton label input[type=checkbox]:checked + .toggle {
  background-color: rgba(25, 118, 210, 0.7);
}
.togglebutton label input[type=checkbox]:checked + .toggle:after {
  border-color: #FD102A;
}
.togglebutton label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(25, 118, 210, 0.1);
}

[class^="icons"] {
  border: 1px solid #CCCCCC;
  width: 2.1333333333em;
  height: 2.1333333333em;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  line-height: 2.1333333333em;
}

.icons {
  border: 1px solid transparent;
}
.icons--circle {
  border-radius: 100%;
}
.icons--rounded {
  border-radius: 3px;
}
.icons--large {
  font-size: 2rem;
}
.icons--huge {
  font-size: 3rem;
}
.icons--fb {
  color: #FFFFFF;
  border: 1px solid #365899;
  background-color: #365899;
}
.icons--fb:hover {
  box-shadow: 0 14px 26px -12px rgba(54, 88, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(54, 88, 153, 0.2);
}
.icons:hover {
  opacity: 0.5;
}

.alert {
  font-size: 1.5rem;
  border: 0;
  padding-top: 0.6666666667em;
  padding-right: 1em;
  padding-bottom: 0.6666666667em;
  padding-left: 1em;
  line-height: 1.3333333333em;
  color: #FFFFFF;
  position: relative;
  background-color: #999999;
}
.alert b {
  text-transform: uppercase;
}
.alert__icon {
  font-size: 70%;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  width: 2em;
  height: 2em;
  line-height: 1.8666666667em;
}
.alert__close {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  cursor: pointer;
}
.alert__close:hover {
  color: #FD102A;
}
.alert__content {
  padding-right: 1.3333333333em;
}

.img--rounded {
  border-radius: 6px;
}
.img--circle {
  border-radius: 100%;
}
.img--raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.label {
  font-size: 1rem;
  border-radius: 10px;
  padding: 0.5em 1.2em 0.5em 1.2em;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background-color: #999999;
}

.dropdownjs {
  position: relative;
  line-height: 0;
}
.dropdownjs > ul, .dropdownjs > .form__select {
  background: #FFFFFF;
  border-radius: 0.2857142857em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.3571428571em 0.3571428571em 0.3571428571em 0.3571428571em;
  overflow: auto;
  width: 100%;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  opacity: 0;
  position: absolute;
  left: 0;
  visibility: hidden;
  z-index: 10000;
  line-height: 1.4285714286em;
}
.dropdownjs > ul li, .dropdownjs > ul .radio, .dropdownjs > .form__select li, .dropdownjs > .form__select .radio {
  font-size: 1.4rem;
  font-weight: 400;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  border-radius: 3px;
  padding: 0.7142857143em 0.7142857143em 0.7142857143em 0.7142857143em;
}
.dropdownjs > ul li:hover, .dropdownjs > ul li.is-selected, .dropdownjs > ul .radio:hover, .dropdownjs > ul .radio.is-selected, .dropdownjs > .form__select li:hover, .dropdownjs > .form__select li.is-selected, .dropdownjs > .form__select .radio:hover, .dropdownjs > .form__select .radio.is-selected {
  box-shadow: 0 14px 26px -12px rgba(255, 102, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 102, 0, 0.2);
  background-color: #FD102A;
  color: #FFFFFF;
}
.dropdownjs > ul li a, .dropdownjs > ul .radio a, .dropdownjs > .form__select li a, .dropdownjs > .form__select .radio a {
  color: inherit;
  display: block;
  position: relative;
}
.dropdownjs > ul li a .badge, .dropdownjs > ul .radio a .badge, .dropdownjs > .form__select li a .badge, .dropdownjs > .form__select .radio a .badge {
  font-size: 78%;
  top: auto;
  right: 0;
}
.dropdownjs > ul {
  width: 200px;
}
.dropdownjs > .icons {
  position: absolute;
  right: 0;
  height: auto;
  width: auto;
  z-index: 1;
}
.dropdownjs > .icons > i {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropdownjs--up ul {
  bottom: 2.5em;
}
.dropdownjs-border {
  border-top: 1px solid #CCCCCC;
  margin: 0.3571428571em 0em 0.3571428571em 0em !important;
  padding: 0 !important;
}
.dropdownjs > .form__input {
  height: auto;
  min-height: 36px;
  cursor: pointer;
}
.dropdownjs .form__select {
  max-height: 217px;
  overflow: auto;
}
.dropdownjs .form__select .radio {
  margin: 0;
}
.dropdownjs .form__select .radio label {
  padding-left: 0;
  display: block;
  color: inherit;
}
.dropdownjs .form__select .radio [class^="radio__"] {
  display: none;
}
.dropdownjs .form__select .checkbox {
  margin: 0.7142857143em;
}
.dropdownjs.is-focused ul, .dropdownjs.is-focused .form__select {
  opacity: 1;
  visibility: visible;
  margin-top: 0.3571428571em;
}
.dropdownjs.is-focused > .form__input {
  outline: none;
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}
.dropdownjs.is-focused > .icons i {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.pagination {
  font-size: 1.2rem;
}
.pagination > li {
  display: inline;
  margin: 0 2px;
}
.pagination > li > a {
  font-size: inherit;
  display: inline-block;
  font-weight: 400;
  border: 0;
  border-radius: 30px;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  line-height: 2.3333333333em;
  color: #999999;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.pagination > li > a:hover {
  background-color: #EEEEEE;
  color: #999999;
}
.pagination > li.active > a {
  background-color: #FD102A;
  color: #FFFFFF;
}
.pagination--circle > li > a {
  border: 1px solid #DDDDDD;
}
.pagination--circle > .active > a {
  border: 1px solid #FD102A;
}
.pagination--rounded > li > a {
  border-radius: 3px;
  border: 1px solid #DDDDDD;
}
.pagination--rounded > .active > a {
  border: 1px solid #FD102A;
}
.pagination--square > li > a {
  border-radius: 0;
  border: 1px solid #DDDDDD;
}
.pagination--square > .active > a {
  border: 1px solid #FD102A;
}
.pagination--right {
  text-align: right;
}
.pagination--center {
  text-align: center;
}
.pagination--large {
  font-size: 1.4rem;
}
.pagination--huge {
  font-size: 1.6rem;
}

.accor {
  margin-bottom: 1em;
}
.accor__header {
  border-bottom: 1px solid #CCCCCC;
  padding: 5px 0px;
  cursor: pointer;
}
.accor__header .icons i {
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}
.accor__header:hover {
  color: #FD102A;
}
.accor__body {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.accor__body div {
  padding: 0.625em 0em 0.625em 0em;
}
.accor.is-active .accor__header {
  color: #FD102A;
}
.accor.is-active .accor__header .icons i {
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  position: relative;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accor.is-active .accor__body {
  visibility: visible;
  height: auto;
}

.breadcrumb {
  padding: 0.6666666667em 0em 0.6666666667em 0em;
}
.breadcrumb [class*="col-"] {
  margin-top: 0em;
  margin-bottom: 0em;
}
.breadcrumb ul {
  display: -webkit-flex;
  display: flex;
}
.breadcrumb li {
  display: inline-block;
  -webkit-align-self: center;
  align-self: center;
  margin-left: 0.4666666667em;
}
.breadcrumb li > a {
  font-weight: bold;
  color: inherit;
}
.breadcrumb li > a:hover {
  color: #FD102A;
}
.breadcrumb li:first-child {
  margin-left: 0;
}
.breadcrumb li:first-child a > i {
  font-size: 3rem;
}
.breadcrumb--gradient {
  background-color: #666666;
  background-image: -webkit-linear-gradient(top, #666666, #333333);
  background-image: linear-gradient(to bottom,#666666, #333333);
}
.breadcrumb--gradient li {
  color: #FFFFFF;
}
.breadcrumb--gradient li > a {
  color: #FFFFFF;
}
.breadcrumb--gradient li > a:hover {
  color: #FD102A;
}
.breadcrumb--border {
  border: 1px solid #CCCCCC;
  border-left: none;
  border-right: none;
}

.slider {
  height: 0.1428571429em;
  background-color: #FD102A;
  cursor: pointer;
  margin: 1.0714285714em auto;
  position: relative;
  width: 80%;
}
.slider.noUi-vertical {
  width: 0.1428571429em;
  height: 17.8571428571em;
}

.noUi-base {
  position: relative;
  width: 100%;
  height: 100%;
}
.noUi-connect {
  background-color: #FD102A;
  cursor: pointer;
  height: inherit;
  position: absolute;
}
.noUi-origin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.noUi-handle {
  position: relative;
  top: -0.4285714286em;
  z-index: 1;
  width: 1em;
  height: 1em;
  border: 1px solid #FD102A;
  border-radius: 100%;
  margin-left: -0.4285714286em;
  background-color: #FFFFFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.noUi-handle.noUi-active {
  -webkit-transform: scale3d(2,2,1);
  -moz-transform: scale3d(2,2,1);
  -o-transform: scale3d(2,2,1);
  -ms-transform: scale3d(2,2,1);
  transform: scale3d(2,2,1);
}
.noUi-pips {
  position: absolute;
  position: absolute;
  top: 100%;
  left: 0;
  color: #999;
  padding: 0.7142857143em 0em 0.7142857143em 0em;
  height: 5.7142857143em;
  width: 100%;
}
.noUi-pips-horizontal {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0.7142857143em 0em 0.7142857143em 0em;
  height: 5.7142857143em;
  width: 100%;
}
.noUi-pips .noUi-marker {
  position: absolute;
  background: #CCC;
  top: -1.0714285714em;
}
.noUi-pips .noUi-marker-horizontal.noUi-marker {
  margin-left: -0.0714285714em;
  width: 0.1428571429em;
}
.noUi-pips .noUi-marker-horizontal.noUi-marker-large {
  height: 0.7142857143em;
}
.noUi-value {
  font-size: 1.4rem;
  position: absolute;
  text-align: center;
  top: -3.2142857143em;
}
.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
}
.noUi-marker-large {
  background: #AAA;
}

.ht-slider__handle {
  text-align: center;
}
.ht-slider__handle input[type="text"] {
  text-align: center;
  width: 5.3571428571em;
  background-size: 0 2px, 100% 0px;
  padding: 0.5em 0em 0.5em 0em;
  border: 1px solid #CCCCCC;
}
.ht-slider__handle span {
  font-size: 1.4rem;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-open {
  overflow: hidden;
}

.modal {
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  overflow: auto;
  overflow-y: scroll;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.modal-box {
  max-width: 600px;
  padding: 4em 0.6666666667em 0.6666666667em 0.6666666667em;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  position: relative;
  z-index: 1050;
}
.modal.show {
  opacity: 1;
}
.modal.show .modal-box {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal .close {
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  top: -1px;
  right: -1px;
  width: 1.6666666667em;
  height: 2em;
  text-align: center;
  border-radius: 0em 0.4em 0em 0.4em;
  line-height: 2em;
}
.modal .close:hover {
  background-color: #FD102A;
}
.modal-group {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 0.4em;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.modal__header {
  padding: 1em;
}
.modal__body {
  padding: 1em;
}
.modal__footer {
  padding: 1em 1em 1em 1em;
  text-align: center;
}

.tooltips {
  font-size: 1.3rem;
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 5px;
  color: #222222;
  z-index: 99999999;
  padding: 10px;
  max-width: 200px;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  -o-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.tooltips::after {
  content: '';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tooltips.show {
  opacity: 1;
  transform: translate(0, 0);
}
.tooltips.top::after {
  content: '';
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #FFFFFF;
  position: absolute;
  bottom: -10px;
  left: 50%;
}
.tooltips.bottom::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid #FFFFFF;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  top: -4px;
  left: 50%;
}
.tooltips.left::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #FFFFFF;
  border-top: 7px solid transparent;
  position: absolute;
  top: 50%;
  right: -10px;
}
.tooltips.right::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #FFFFFF;
  border-top: 7px solid transparent;
  position: absolute;
  top: 50%;
  left: -4px;
}

.popovers {
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 5px;
  color: #222222;
  z-index: 1;
  padding: 10px;
  max-width: 300px;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  -o-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.popovers::after {
  content: '';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.popovers.show {
  opacity: 1;
  transform: translate(0, 0);
}
.popovers__title {
  font-size: 2rem;
  margin-bottom: 0.25em;
}
.popovers__content {
  font-size: 1.4rem;
}
.popovers.top::after {
  content: '';
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #FFFFFF;
  position: absolute;
  bottom: -10px;
  left: 50%;
}
.popovers.bottom::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid #FFFFFF;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  top: -4px;
  left: 50%;
}
.popovers.left::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #FFFFFF;
  border-top: 7px solid transparent;
  position: absolute;
  top: 50%;
  right: -10px;
}
.popovers.right::after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #FFFFFF;
  border-top: 7px solid transparent;
  position: absolute;
  top: 50%;
  left: -4px;
}

.datepicker {
  position: relative;
}
.datepicker .icons {
  position: absolute;
  right: 0;
  height: auto;
  width: auto;
  z-index: 1;
}

.picker {
  font-size: 1.4rem;
  width: 100%;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 0.2142857143em;
}

.picker__holder {
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #CCCCCC;
  border-radius: 0.2142857143em;
  max-width: 20.3571428571em;
  min-width: 20.3571428571em;
  max-height: 0;
  opacity: 0;
  -webkit-transform: translateY(-1em) perspective(600px) rotateX(10deg);
  transform: translateY(-1em) perspective(600px) rotateX(10deg);
  -webkit-transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s;
  -o-transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.picker--opened .picker__holder {
  max-height: 23.2142857143em;
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

.picker__box {
  padding: 1.0714285714em 1.0714285714em 1.0714285714em 1.0714285714em;
}

.picker__header {
  text-align: center;
  position: relative;
}

.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
}

.picker__year {
  color: #999999;
  font-style: italic;
}

.picker__select--month,
.picker__select--year {
  border: 1px solid #FD102A;
  margin-left: 0.2142857143em;
}

.picker__select--month:focus,
.picker__select--year:focus {
  border-color: #FD102A;
}

.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  top: -3px;
  padding: 0.5em 1.1428571429em 0.5em 1.1428571429em;
}

.picker__nav--prev {
  left: -1.0714285714em;
}

.picker__nav--next {
  right: -1.0714285714em;
}

.picker__nav--prev:before,
.picker__nav--next:before {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 0.5714285714em solid transparent;
  border-right: 0.5714285714em solid #FD102A;
  border-top: 0.5714285714em solid transparent;
  display: block;
}

.picker__nav--next:before {
  border-right: 0;
  height: 0;
  width: 0;
  border-bottom: 0.5714285714em solid transparent;
  border-left: 0.5714285714em solid #FD102A;
  border-top: 0.5714285714em solid transparent;
}

.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #FFFFFF;
  background: #8cbae8;
}

.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}

.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: 0.7142857143em;
  margin-bottom: 0.3571428571em;
}

.picker__table td {
  margin: 0;
  padding: 0;
}

.picker__weekday {
  font-size: 1.3rem;
  width: 2.5714285714em;
  color: #999999;
  font-weight: 600;
}

.picker__day {
  padding: 0.3571428571em 0em 0.3571428571em 0em;
  font-weight: 200;
  border: 1px solid transparent;
  border-radius: 0.2142857143em;
}

.picker__day--today {
  position: relative;
  border: 1px solid #FD102A;
}

.picker__day--today:before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  height: 0;
  width: 0;
  border-top: 0.5em solid #FD102A;
  border-left: 0.5em solid transparent;
}

.picker__day--disabled:before {
  border-top-color: #aaaaaa;
}

.picker__day--outfocus {
  color: #dddddd;
}

.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #222222;
  background: #8cbae8;
}

.picker__day--highlighted {
  border-color: #FD102A;
}

.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #FFFFFF;
  background: #8cbae8;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background: #FD102A;
  color: #FFFFFF;
}

.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}

.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbbbbb;
}

.picker__footer {
  text-align: center;
}

.picker__button--today,
.picker__button--clear,
.picker__button--close {
  width: 5em;
  padding: 0.5714285714em 0em 0.5714285714em 0em;
  margin: 0em 0.1428571429em 0em 0.1428571429em;
  background-color: #FD102A;
}

.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
  position: relative;
  display: inline-block;
  height: 0;
}

.picker__button--today:before,
.picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}

.picker__button--today:before {
  position: relative;
  top: -2px;
  height: 0;
  width: 0;
  border-top: 0.3571428571em solid #FFFFFF;
  border-left: 0.3571428571em solid transparent;
}

.picker__button--clear:before {
  top: -0.25em;
  width: .66em;
  border-top: 3px solid #FFFFFF;
}

.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaaaaa;
}

.thumbnail {
  line-height: 0;
  margin-bottom: 0.6666666667em;
  overflow: hidden;
  display: inline-block;
}

.uploader {
  display: inline-block;
  max-width: 24em;
  text-align: center;
}
.uploader__preview {
  display: none;
}
.uploader.is-active .uploader__preview, .uploader.is-active .select-file__change, .uploader.is-active .select-exists {
  display: inline-block;
}
.uploader.is-active .uploader__none, .uploader.is-active .select-file__new {
  display: none;
}
.uploader .select-exists {
  display: none;
}
.uploader .select-file > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}
.uploader .select-file__change {
  display: none;
}
.uploader.uploader-file {
  border-bottom: 1px solid #CCCCCC;
  max-width: none;
  position: relative;
}
.uploader.uploader-file > .uploader__preview, .uploader.uploader-file .uploader__none {
  float: left;
}
.uploader.uploader-file .uploader__input {
  float: right;
}
.uploader.uploader-file > .uploader__preview, .uploader.uploader-file .uploader__none {
  font-size: 1.5rem;
  padding: 0.2666666667em 0em 0.2666666667em 0em;
  text-align: left;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.uploader.uploader-file .select-file {
  font-size: 97%;
}

.navbar {
  font-size: 1.5rem;
  border-radius: 3px;
}
.navbar--primary {
  background-color: #FD102A;
}
.navbar--tranparent {
  background-color: transparent;
}
.navbar .menu__search {
  padding: 0em 1em 0em 1em;
  background: none;
}
.navbar .menu__search .form__input {
  background-image: linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(#CCCCCC, #CCCCCC);
  color: #FFFFFF;
}
.navbar .menu__search .form__input::-webkit-input-placeholder {
  color: #FFFFFF !important;
  font-style: none !important;
}
.navbar .menu__search .form__input::-moz-placeholder {
  color: #FFFFFF !important;
  font-style: none !important;
}
.navbar .menu__search .form__input:-moz-placeholder {
  color: #FFFFFF !important;
  font-style: none !important;
}
.navbar .menu__search .form__input:-ms-input-placeholder {
  color: #FFFFFF !important;
  font-style: none !important;
}

@media (min-width: 1025px) {
  .menu--left {
    float: left;
  }
  .menu--right {
    float: right;
  }
  .menu--right .dropdownjs ul {
    left: auto;
    right: 0;
  }
}
.menu .badge {
  position: absolute;
  top: -5px;
  right: -5px;
}
.menu__mobie {
  display: none;
}
.menu__mobie-toggle {
  background-color: transparent;
  padding: 0.4em 0.6em 0.4em 0.6em;
  box-shadow: none;
  float: right;
  font-size: inherit;
}
.menu__mobie-toggle-icon {
  display: block;
  width: 1.6666666667em;
  height: 0.1333333333em;
  border-radius: 0.0666666667em;
  background-color: #FFFFFF;
  margin: 0.2666666667em 0em 0.2666666667em 0em;
}
.menu__mobie-name {
  color: #FFFFFF;
  display: inline-block;
  padding: 0.6666666667em 1em 0.6666666667em 1em;
}
.menu__item > li {
  float: left;
  position: relative;
}
.menu__item > li > a {
  font-size: 1.4rem;
  color: #FFFFFF;
  display: block;
  padding: 0.8666666667em 1em 0.8666666667em 1em;
  line-height: 1;
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}
.menu__item > li > a:hover {
  background-color: rgba(0, 0, 0, 0.33);
}
.menu__item > li.is-active > a {
  background-color: rgba(0, 0, 0, 0.33);
}
.menu__item .dropdownjs > ul li {
  font-size: inherit;
}
.menu__search {
  padding: 0.6666666667em 1.3333333333em 1em 1.3333333333em;
  background: rgba(0, 0, 0, 0.09);
}
.menu__search .form-group {
  margin-bottom: 0;
}
.menu__search .form__input {
  width: calc(100% - 45px);
}

@media (min-width: 1px) and (max-width: 1024px) {
  .menu__mobie {
    display: block;
  }

  .menu__collapse {
    display: none;
    float: left;
    width: 100%;
  }
  .menu__collapse .menu__item {
    width: 100%;
  }
  .menu__collapse .menu__item li {
    float: none;
  }
}
.tabs {
  position: relative;
  font-size: 1.5rem;
}
@media (min-width: 769px) {
  .tabs-list > li {
    float: left;
  }
}
.tabs-list > li .tabs__title {
  cursor: pointer;
  color: #222222;
  line-height: 1;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.tabs-list > li .tabs__title--vertical > i {
  display: block;
  padding-top: 0.3571428571em;
  padding-bottom: 0.3571428571em;
}
.tabs-list > li .tabs__title--vertical > span {
  margin-left: 0;
}
.tabs-list > li .tabs__title .icons {
  height: auto;
  width: auto;
  line-height: 0;
}
@media (min-width: 769px) {
  .tabs-list > li .tabs__title .icons {
    display: none;
  }
}
.tabs-list > li .tabs__content {
  width: 100%;
  z-index: 1;
  opacity: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
@media (min-width: 769px) {
  .tabs-list > li .tabs__content {
    padding: 1em 0em 1em 0em;
    position: absolute;
    left: 0;
  }
}
.tabs-list > li .tabs__content-comment {
  font-size: 1.5rem;
  position: relative;
}
@media (min-width: 1025px) {
  .tabs-list > li .tabs__content-comment {
    min-height: 200px;
    border-right: 1px solid #CCCCCC;
    padding-right: 3.3333333333em;
  }
  .tabs-list > li .tabs__content-comment .nocomment {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 200px;
  }
}
.tabs-list > li .tabs__content-comment .comment .comment__body {
  width: 100%;
}
.tabs-list > li .tabs__content-comment .comment .comment {
  margin-left: 2em;
}
.tabs-list > li.is-active .icons {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.tabs-list > li.is-active > .tabs__content {
  height: auto;
  opacity: 1;
  visibility: visible;
  padding: 1em 0em 1em 0em;
}
.tabs-border .tabs-list > li .tabs__title {
  border-bottom: 2px solid #CCCCCC;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.6666666667em 0em 0.6666666667em 0em;
}
.tabs-border .tabs-list > li.is-active .tabs__title {
  color: #222222;
  border-bottom: 2px solid #ff6701;
}
.tabs-border .tabs-list > li:hover .tabs__title {
  border-bottom: 2px solid #ff6701;
}
@media (min-width: 769px) {
  .tabs-border {
    border-bottom: 1px solid #CCCCCC;
  }
  .tabs-border .tabs-list {
    border-bottom: 1px solid #CCCCCC;
  }
  .tabs-border .tabs-list > li .tabs__title {
    border-bottom: 1px solid #CCCCCC;
    margin-right: 2.6666666667em;
    border-bottom: 2px solid transparent;
    padding: 0.3333333333em 0em 0.3333333333em 0em;
  }
}
.tabs-vertical {
  float: left;
  width: 100%;
}
.tabs-vertical > .tabs-list {
  float: left;
}
.tabs-vertical > .tabs-list > li {
  float: none;
  margin-bottom: 0.1333333333em;
}
.tabs-vertical > .tabs-list > li .tabs__title {
  text-align: center;
  border-radius: 3px;
  padding: 0.6666666667em 1em 0.6666666667em 1em;
}
@media (max-width: 768px) {
  .tabs-vertical > .tabs-list > li .tabs__title {
    background-color: #FD102A;
    color: #FFFFFF;
  }
}
.tabs-vertical > .tabs-list > li .tabs__content {
  left: auto;
  right: 0;
  top: 0;
  padding: 0em 0.6666666667em 0em 0.6666666667em;
}
.tabs-vertical > .tabs-list > li.is-active .tabs__title, .tabs-vertical > .tabs-list > li:hover .tabs__title {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.33);
}

.card {
  font-size: 1.5rem;
  background-color: #FFFFFF;
  margin-bottom: 1.3333333333em;
}
.card-title {
  font-weight: 600;
}
a > .card-title:hover {
  color: #FD102A;
}
@media (max-width: 1024px) {
  .card-description {
    max-width: 23.3333333333em;
    margin: 0 auto;
  }
}
.card__image {
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  line-height: 0;
}
.card__image img {
  width: 100%;
}
.card__content {
  padding: 1em 1em 0.6666666667em 1em;
}
.card__content .author h1 {
  font-size: 1.5rem;
  font-weight: 700;
}
.card__content .avatar {
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;
}
.card__content .description i {
  color: #999999;
}
.card__content .content__source {
  margin-bottom: 0.3333333333em;
}
.card__content .content__source li {
  display: inline-block;
  font-size: 1.2rem;
  margin-right: 0.4166666667em;
}
.card__content .content__link .title:hover {
  color: #FD102A;
}
.card__footer .btn--simple {
  font-size: 100%;
  margin: 0.4em 0em 0.6666666667em 0em;
}
.card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
}
.card .overlay--azure {
  background-color: rgba(0, 146, 248, 0.69);
}
.card .overlay .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.5;
}
.card .overlay .btn:hover {
  opacity: 1;
}
.card:hover .overlay {
  opacity: 1;
}
.card.card-horizontal .card__content {
  padding: 0;
}
@media (min-width: 320px) {
  .card.card-horizontal .card__image {
    float: left;
    margin-right: 15px;
  }
}
.card--profile {
  -webkit-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}
.card--profile:hover {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card--profile hr {
  margin: 0.3333333333em 1em 0.3333333333em 1em;
}
.card--profile .card__image {
  height: 0;
  margin-top: 7.3333333333em;
}
.card--profile .card__image--height {
  height: 7.3333333333em;
  margin-top: 0;
}
.card--profile .card__content .author {
  text-align: center;
  text-transform: none;
  margin-top: -4.6666666667em;
}
.card--profile .card__content .title {
  color: #333333;
}
.card--profile .card__content .description {
  font-size: 1.4rem;
  max-width: 23.3333333333em;
  margin: 0 auto;
}
.card--profile .card__content .avatar {
  width: 8.2666666667em;
  height: 8.2666666667em;
  border: 5px solid #EEEEEE;
  position: relative;
  margin-bottom: 0.6666666667em;
}
.card--pricing {
  text-align: center;
  -webkit-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}
.card--pricing:hover {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card--pricing .card__content {
  max-width: 350px;
  margin: 0 auto;
}
.card--pricing .category {
  color: #222222;
}
.card--pricing .price {
  margin: 0.8888888889em 0em 0.6666666667em 0em;
}
.card--pricing .list-unstyled {
  font-size: 1.4rem;
  padding-bottom: 3.3333333333em;
  color: #9A9A9A;
  text-align: left;
}
.card--pricing .list-unstyled li {
  padding: 0.6666666667em 0em 0.6666666667em 0em;
  border-bottom: 1px solid #E3E3E3;
}
.card--testimonial {
  -webkit-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}
.card--testimonial:hover {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card--testimonial .card__icon {
  text-align: center;
  font-size: 4.6rem;
  padding-top: 0.5434782609em;
  color: #AAAAAA;
}
.card--testimonial .author {
  line-height: 0;
  text-align: center;
  margin-bottom: -3.6666666667em;
}
.card--testimonial .avatar {
  border-radius: 50%;
  width: 6.6666666667em;
  height: 6.6666666667em;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: relative;
}
.card--testimonial .card__image {
  height: 0;
  margin-bottom: 5.7333333333em;
}
.card--testimonial .card__image--height {
  height: 5.7333333333em;
  margin-bottom: 0;
}
.card--testimonial .title {
  margin: 2em 0em 2em 0em;
  color: #333333;
}
.card--testimonial .description {
  font-size: 1.8rem;
  color: #999999;
  font-style: italic;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 300;
  max-width: 23.3333333333em;
  margin: 0 auto;
}
.card--news {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .card--news.card-horizontal .card__image {
    max-width: 270px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .card--news.card-horizontal .card__image {
    max-width: 200px;
  }
}
/* @media (min-width: 360px) and (max-width: 480px) {
  .card--news.card-horizontal .card__image {
    max-width: 150px;
  }
} */
.card--news .card-title {
  height: 2.3333333333em;
  overflow: hidden;
  margin-top: 0;
}
.card--news-detail .title {
  font-weight: 900;
}
.card--news-detail .card__content {
  padding: 0;
}
.card--news-detail .content__desc {
  text-align: justify;
  margin: 15px 0px 25px;
  line-height: 1.3333333333em;
  position: relative;
}
.card--news-detail .content__desc:before {
  content: "\f10d";
  font: normal normal normal 24px/1 FontAwesome;
  margin-right: 7px;
  color: #999999;
}
.card--news-detail .content__desc:after {
  content: "\f10e";
  font: normal normal normal 24px/1 FontAwesome;
  margin-left: 7px;
  color: #999999;
  position: absolute;
  bottom: -10px;
}
.content__detail img {
    height: auto !important;
}
.card--news-detail .content__detail {
  line-height: 1.3333333333em;
  margin-bottom: 2em;
}
.card--news-detail .content__detail a {
  color: blue;
  background: none;
}
.card--news-detail .content__detail a:hover {
  color: #FD102A;
}
.card--news-detail .content__detail ul, .card--news-detail .content__detail ol {
  list-style: initial;
  padding-left: 15px;
}
.card--projects {
  margin-bottom: 0.6666666667em;
}
.card--projects .card-description {
  font-size: 1.5rem;
}
.card--products {
  padding: 0.6666666667em 0.6666666667em 0.6666666667em 0.6666666667em;
  -webkit-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  transition: border 0.2s linear;
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
}
.card--products .sale-percent {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 0em 0.4666666667em 0em 0.4666666667em;
  color: #F00;
}
.sale-percent small{
  font-size: 50%;
  color: inherit;
}
.card--products .card__image {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
}
.card--products .card__image .card-hot {
  font-size: 1.2rem;
  position: absolute;
  top: 9px;
  left: -17px;
  position: absolute;
  background: #FFFFFF;
  transform: rotate(-45deg);
  width: 5.8333333333em;
  text-align: center;
  border-bottom: 1px solid #FD102A;
  padding: 0.9166666667em 0em 0.5833333333em 0em;
  color: #f00;
}
.card--products .card__image .sale-percent {
  position: absolute;
  right: 0;
  bottom: 0;
}
.card--products .card__content {
  padding: 0;
  position: relative;
}
.card--products .card__content .card-title {
  height: 40px;
  overflow: hidden;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .card--products.card-horizontal .card__image {
    max-width: 150px;
    height: auto;
  }
}
.card--products:hover {
  border: 1px solid #CCCCCC;
  border-bottom: 2px solid #FD102A;
}
.card--products-slide {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  border: none;
  padding: 0;
  position: relative;
}
.card--products-slide .card__image {
  padding: 0.6666666667em 0.6666666667em 0em 0.6666666667em;
  border: 1px solid transparent;
  border-bottom: 0;
  width: 100%;
  display: block;
}
.card--products-slide .card__image-slide {
  width: 100%;
}
.card--products-slide .card__content {
  padding: 0em 0.6666666667em 0em 0.6666666667em;
  border: 1px solid transparent;
  border-top: 0;
  border-bottom: 2px solid transparent;
}
.card--products-slide .slider-nav {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  height: 0;
  padding: 0em 1em 0em 1em;
  visibility: hidden;
  width: 100%;
}
.card--products-slide .slider-nav .slick-track {
  margin: 0 auto;
}
.card--products-slide .slider-nav .item {
  margin: 0em 0.2em 0em 0.2em;
}
.card--products-slide .slider-nav .slick-prev, .card--products-slide .slider-nav .slick-next {
  width: 1em;
}
.card--products-slide .slick-slider:hover .slick-prev, .card--products-slide .slick-slider:hover .slick-next {
  background-color: rgba(0, 0, 0, 0.7);
}
@media (min-width: 1025px) {
  .card--products-slide:hover {
    border: none;
  }
  .card--products-slide:hover .card__content {
    position: absolute;
    left: 0;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-bottom: 2px solid #FD102A;
    border-top: 0;
    background-color: inherit;
    z-index: 9999;
  }
  .card--products-slide:hover .card__image {
    border: 1px solid #CCCCCC;
    border-bottom: 0;
  }
  .card--products-slide:hover .slider-nav {
    padding: 1em;
    height: auto;
    visibility: visible;
  }
}
.card--products-detail {
  padding: 0;
}
.card--products-detail .card-title {
  color: #666666;
}
.card--products-detail .card__image {
  width: 100%;
  display: block;
  height: auto;
  margin-bottom: 1.3333333333em;
}
.card--products-detail .card__image-square {
  margin: 10px;
  text-align: center;
}
.card--products-detail .card__image-square img {
  border-radius: 3px;
  height: auto;
}
.card--products-detail .card__image-rectangle {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 10px;
}
.card--products-detail .slider-nav {
  padding: 0em 1em 0em 1em;
  width: 100%;
}
.card--products-detail .slider-nav .slick-track {
  margin: 0 auto;
}
.card--products-detail .slider-nav .item {
  margin: 0em 0.2em 0em 0.2em;
}
.card--products-detail .slider-nav .slick-prev, .card--products-detail .slider-nav .slick-next {
  width: 1em;
}
.card--products-detail .slider-nav .item img {
  padding: 5px;
  border: 1px solid transparent;
}
.card--products-detail .slider-nav .item.active img, .card--products-detail .slider-nav .item:hover img {
  border: 1px solid #CCCCCC;
}
.card--products-detail .slick-slider:hover .slick-prev, .card--products-detail .slick-slider:hover .slick-next {
  background-color: rgba(0, 0, 0, 0.7);
}
.card--products-detail:hover {
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
}
.card--products-detail.card-horizontal .card__content {
  float: right;
  width: calc(100% - 100px);
}
.card--products-detail.card-horizontal .card__image {
  max-width: 100px;
  margin: 0;
}
@media (max-width: 1024px) {
  .card--products-detail.card-horizontal .card__content {
    width: calc(100% - 50px);
  }
  .card--products-detail.card-horizontal .card__image {
    max-width: 50px;
  }
}
.card--products-detail .card-listinfo {
  font-size: 1.5rem;
}
.card--products-detail .card-listinfo > li {
  line-height: 1.6666666667em;
}
.card--products-detail .card-listinfo > li.delimited {
  padding: 5px 0;
}
.card--products-detail .card-listinfo > li span:first-child {
  display: inline-block;
  width: 6.6666666667em;
  color: #666666;
}
.card--products-detail .btn-bocking {
  display: block;
  text-align: center;
}
@media (min-width: 361px) {
  .card--products-detail .btn-bocking {
    position: absolute;
    right: 0;
    bottom: 1em;
  }
  .card--products-detail .share {
    font-size: 1.3rem;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 1px;
  }
  .card--products-detail .share span:first-child {
    margin-right: 7px;
    vertical-align: middle;
  }
}
.card--products-detail .card__hotline {
  margin-top: 30px;
  height: 2.6666666667em;
  background-color: #EEEEEE;
  line-height: 2.6666666667em;
  border-left: 5px solid #FD102A;
}
.card--products-detail .card__hotline span {
  display: inline-block;
  font-weight: 600;
  color: #F00;
  padding-left: 0.6666666667em;
}
.card--products-detail .card__hotline span:first-child {
  padding: 0em 0.6666666667em 0em 0.6666666667em;
  background-color: #DDDDDD;
  color: #FD102A;
}
.card--products-detail .table {
  margin: 1.3333333333em 0em 1.3333333333em 0em;
}
.card--rounded {
  border-radius: 6px;
}
.card--rounded .card__image {
  border-radius: 6px 6px 0 0;
  position: relative;
  overflow: hidden;
}
/*.card--rounded .card__image:before {
    content: '';
    display: block;
    padding-bottom: 100%;
}*/
/*.card--rounded .card__image > .wrap-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}*/
.card--rounded.card-horizontal .card__image {
  border-radius: 6px 0 0 6px;
}
.card--raised {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.23);
}
.card--primary {
  background: #FD102A;
}
.card--primary .category, .card--primary .price, .card--primary .list-unstyled, .card--primary small {
  color: #FFFFFF !important;
}
.card--border {
  border: 1px solid #EEEEEE;
}
.card--title .overlay {
  line-height: 1.2;
  height: 3em;
  bottom: 0px;
  opacity: 1;
  text-align: left;
  top: auto;
  padding: 0.3333333333em 0.6666666667em 0.3333333333em 0.6666666667em;
}
.card--title-vertical .overlay {
  height: 100%;
  width: 50%;
  background-color: rgba(231, 198, 198, 0.25);
}
.card--title-vertical .overlay .title {
  position: absolute;
  bottom: 0;
}
/*.card--title img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.card--title:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}*/

.badge {
  font-size: 1.1rem;
  display: inline-block;
  width: 1.8181818182em;
  height: 1.8181818182em;
  font-weight: 700;
  line-height: 1.8181818182em;
  border-radius: 50%;
  background-color: #999999;
  text-align: center;
  color: #FFFFFF;
}
.badge--rounded {
  border-radius: 3px;
}

.star {
  font-size: 1.5rem;
}
.star input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.star label {
  color: #CCCCCC;
}
.star label:hover, .star label.is-active {
  color: yellow;
}

.comment {
  font-size: 1.5rem;
  border-bottom: 1px solid #DDDDDD;
  padding-bottom: 2em;
  margin-top: 2em;
}
.comment__author {
  float: left;
}
.comment__author .avatar {
  width: 2.6666666667em;
  height: 2.6666666667em;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid transparent;
  margin: 0 auto;
}
.comment__author .avatar img {
  width: 100%;
}
.comment__body {
  width: calc(100% - 40px);
  float: right;
}
.comment__body p {
  line-height: 1.1;
}
.comment__footer {
  margin-bottom: 0.6666666667em;
}
.comment__footer button:hover {
  color: #ff6701;
}
.comment__footer button:hover .text--icons {
  color: #ff6701;
}
.comment__name, .comment__time {
  display: inline-block;
}
.comment .comment {
  padding-bottom: 0px;
  border-bottom: 0px solid #DDDDDD;
  margin-top: 0px;
}
.comment .comment--post {
  height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.comment .comment--post.show {
  height: auto;
  opacity: 1;
}

.feature {
  font-size: 1.5rem;
  max-width: 24em;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 769px) {
  .feature {
    padding: 1em 0em 1em 0em;
  }
}
.feature__icon > i {
  font-size: 4.6666666667em;
}
.feature__desc {
  color: #999999;
}
.feature--horizontal {
  max-width: none;
}
.feature--horizontal .feature__icon {
  float: left;
  margin-top: 0.4666666667em;
  margin-right: 0.6666666667em;
}
.feature--horizontal .feature__icon > i {
  font-size: 2em;
}
.feature--horizontal .feature__body {
  overflow: hidden;
  text-align: left;
}

.features-3 .features-image {
  max-width: 260px;
  margin: 30px auto 0;
}
.features-3 .row > .feature {
  padding: 0;
}
.features-3 .row > .feature:first-child {
  padding-top: 70px;
}

.features-4 .container {
  padding: 50px 0px 0px;
}
@media (min-width: 769px) {
  .features-4 .container [class*="col-"] {
    margin: 0;
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-top: 0;
    border-left: 0;
  }
  .features-4 .container [class*="col-"]:last-child {
    border-right: 0;
  }
  .features-4 .container .row:last-child [class*="col-"] {
    border-bottom: 0;
  }
}
.features-4 .feature__icon > i {
  font-size: 2em;
}
.features-4 .feature__title {
  font-size: 1.1333333333em;
}

.slick-slider.slide {
  max-height: 400px;
  overflow: hidden;
}
.slick-slider:hover .slick-prev, .slick-slider:hover .slick-next {
  color: #FFFFFF;
}

.slick-prev, .slick-next {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font: normal normal normal 14px/1 FontAwesome;
  color: #CCCCCC;
  height: 45px;
  width: 45px;
  max-width: 45px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.slick-prev:hover, .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.slick-prev {
  left: 0;
}
.slick-prev::before {
  content: "\f053";
}

.slick-next {
  right: 0;
}
.slick-next::before {
  content: '\f054';
}

.slider-nav .item {
  position: relative;
  cursor: pointer;
}
.slider-nav .slick-slide .overlay {
  opacity: 1;
}
.slider-nav .slick-current .overlay, .slider-nav .slick-slide:hover .overlay {
  opacity: 0;
}

.slick-vertical .slick-prev, .slick-vertical .slick-next {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: auto;
  transform: translateX(-50%);
}
.slick-vertical .slick-prev {
  top: 0;
}
.slick-vertical .slick-prev::before {
  content: "\f077";
}
.slick-vertical .slick-next {
  bottom: 0;
}
.slick-vertical .slick-next::before {
  content: "\f078";
}
.slick-vertical.slider-nav {
  padding: 1em 0em 1em 0em;
  width: 100%;
}
.slick-vertical.slider-nav .slick-track {
  margin: 0 auto;
}
.slick-vertical.slider-nav .item {
  margin: 0.2em 0em 0.2em 0em;
}
.slick-vertical.slider-nav .slick-prev, .slick-vertical.slider-nav .slick-next {
  height: 1em;
  width: 100%;
}
.slick-vertical.slider-nav .item img {
  padding: 0.3333333333em;
  border: 1px solid transparent;
}
.slick-vertical.slider-nav .item.active img, .slick-vertical.slider-nav .item:hover img {
  border: 1px solid #CCCCCC;
}
.slick-vertical.slider-nav:hover .slick-prev, .slick-vertical.slider-nav:hover .slick-next {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-login .modal-box {
  max-width: 23.3333333333em;
}
.modal-login .modal-login_social a {
  margin: 0em 0.3333333333em 0em 0.3333333333em;
}
.modal-login .divider {
  margin: 1.6666666667em 1em 0em 1em;
  display: block;
  text-align: center;
  position: relative;
}
.modal-login .divider::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 5px;
  width: 7.6666666667em;
  height: 0.0666666667em;
  background-color: #E3E3E3;
}
.modal-login .divider::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 5px;
  width: 7.6666666667em;
  height: 0.0666666667em;
  background-color: #E3E3E3;
}
.modal-login .form-checkbox {
  font-size: 1.2rem;
  margin-left: 1.6666666667em;
}
.modal-login .form-checkbox .checkbox {
  margin: 0;
}

@media (min-width: 600px) {
  .modal-register .modal-box {
    max-width: 40em;
  }
  .modal-register .modal-group::after {
    content: "";
    display: block;
    clear: both;
  }
  .modal-register .modal-left {
    min-width: 23.3333333333em;
    float: left;
  }
  .modal-register .modal-right {
    max-width: calc(100% - 350px);
    float: left;
  }
  .modal-register .modal-right .modal__body {
    margin-top: 4.3333333333em;
  }
  .modal-register .modal-right .modal__body .divider {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0.0666666667em;
    height: 20em;
    background-color: rgba(255, 0, 0, 0);
    background-image: -webkit-linear-gradient(top, rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
    background-image: linear-gradient(to bottom,rgba(255, 0, 0, 0), #CCCCCC 50%, rgba(255, 0, 0, 0));
    margin: 0;
  }
  .modal-register .modal-right .modal__body .divider::after, .modal-register .modal-right .modal__body .divider::before {
    height: 0;
  }
  .modal-register .modal-login_social a {
    margin: 1em 0em 1em 0em;
    display: block;
    text-align: left;
  }
  .modal-register .modal-login_social {
    margin-left: 2em;
  }
}
@media (max-width: 600px) {
  .modal-register .divider {
    display: none;
  }
  .modal-register .modal__body {
    padding: 0em 1.0666666667em 0em 1.0666666667em;
  }
  .modal-register .modal-right {
    padding-bottom: 1.0666666667em;
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.table {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
}
.table td, .table th {
  padding: 0.4666666667em;
  border-bottom: 1px solid #cccccc;
}
.table tr:last-child td {
  border: none;
}
.table td:first-child, .table th:first-child {
  padding-left: 0;
}
.table td:last-child, .table th:last-child {
  padding-right: 0;
}
.table .td-name{
  max-width: 20%;
}
.table .td-image {
  width: 6%;
  line-height: 0;
}
.table .td-image img {
  width: 50px;
}
.table .td-number {
  width: 10%;
}
.table .td-quantity {
  font-size: 80%;
}
.table .td-quantity input {
  margin: 0 auto;
  width: 2.1333333333em;
  height: 2.1333333333em;
  display: block;
  text-align: center;
  border: 1px solid #cccccc;
  font-size: inherit;
}
.table .td-remove {
  width: 1%;
}
@media (max-width: 768px) {
  .td-image, .th-image {
    display: none;
  }
}
@media (max-width: 480px) {
  .table {
    font-size: 78%;
  }

  .td-product p, .td-product ul {
    display: none;
  }
}
[class^="pre-footer"] .card {
  margin-top: 20px;
}
[class^="pre-footer"] .form-group {
  width: calc(100% - 115px);
}

[class^="products-"] .slide-product {
  max-height: none;
  padding: 0 20px;
}
[class^="products-"] .slide-product [class^="col-"] {
  margin-left: 0;
}
[class^="products-"] .slide-product .slick-prev, [class^="products-"] .slide-product .slick-next {
  box-shadow: none;
  height: auto;
  width: auto;
}
[class^="products-"] .slide-product .slick-prev:hover, [class^="products-"] .slide-product .slick-next:hover {
  background-color: transparent;
}
[class^="products-"] .slide-product:hover .slick-prev, [class^="products-"] .slide-product:hover .slick-next {
  color: #FD102A;
}
[class^="products-"] .thumbnail {
  margin: 10px 0 0 0;
  background-position: center center;
  background-size: cover;
  width: 100%;
}
@media (min-width: 1025px) {
  [class^="products-"] .thumbnail {
    height: 310px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  [class^="products-"] .thumbnail {
    height: 310px;
  }
}

.sidebar {
  font-size: 1.5rem;
}
.sidebar-section {
  margin-bottom: 2em;
}
.sidebar__title {
  text-transform: uppercase;
}
.sidebar__items {
  margin-top: 1.3333333333em;
}
.sidebar__more {
  font-size: 1.2rem;
  cursor: pointer;
  color: #FD102A;
}
.sidebar__more:hover {
  color: #FD102A;
}
.sidebar .form__checkbox {
  max-height: 16.6666666667em;
  overflow: auto;
}
.sidebar-multicates .sidebar__title {
  color: #FD102A;
  border-bottom: 1px solid #FD102A;
  font-weight: 600;
}
.sidebar-multicates .sidebar__items {
  max-height: 39em;
  overflow: hidden;
}
.sidebar-multicates .sidebar__items > ul > li > a {
  display: block;
  padding: 0.3333333333em 0em 0.1333333333em 0em;
  color: #222222;
}
.sidebar-multicates .sidebar__items > ul > li > a .icons {
  display: none;
  height: auto;
  width: auto;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sidebar-multicates .sidebar__items > ul > li:hover > a {
  color: #FD102A;
}
.sidebar-multicates .sidebar__items > ul > li > ul li:hover > a {
  color: #FD102A;
}
@media (max-width: 1024px) {
  .sidebar-multicates .sidebar__title {
    border: none;
    background-color: #999999;
    color: #FFFFFF;
    padding: 0.6em 1em 0.6em 1em;
  }
  .sidebar-multicates .sidebar__items {
    max-height: none;
    margin-top: 0;
  }
  .sidebar-multicates .sidebar__items > ul > li a {
    padding: 0.6em 1em 0.6em 1em;
    border: 1px solid #CCCCCC;
    border-top: none;
    position: relative;
    display: block;
  }
  .sidebar-multicates .sidebar__items > ul > li a strong {
    font-weight: inherit;
  }
  .sidebar-multicates .sidebar__items > ul > li a .icons {
    display: block;
  }
  .sidebar-multicates .sidebar__items > ul > li > ul, .sidebar-multicates .sidebar__items > ul > li > ul ul {
    display: none;
  }
  .sidebar-multicates .sidebar__items > ul > li.is-active > a {
    color: #FD102A;
  }
  .sidebar-multicates .sidebar__items > ul > li.is-active > a .icons i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sidebar-multicates .sidebar__items > ul > li.is-active > ul a {
    padding-left: 1.3333333333em;
  }
  .sidebar-multicates .sidebar__more {
    display: none;
  }
}
.sidebar-multicates--style-01 ul ul li a {
  display: block;
  padding-left: 0.6666666667em;
}
.sidebar-multicates--style-01 ul ul li a i {
  font-size: 1.2rem;
}
.sidebar-multicates--style-01 ul ul li a span {
  width: calc(100% - 13px);
  float: right;
  margin-left: 0;
}
.sidebar-multicates--style-02 {
  position: relative;
}
.sidebar-multicates--style-02 .sidebar__title {
  border: none;
  background-color: #999999;
  color: #FFFFFF;
  padding: 0.6em 1em 0.6em 1em;
}
.sidebar-multicates--style-02 .sidebar__items {
  margin-top: 0;
}
.sidebar-multicates--style-02 .sidebar__items > ul > li > a {
  background-position: 0.6666666667em center;
  background-repeat: no-repeat;
  background-size: 1.4666666667em;
  line-height: 1.3;
  padding: 0.6666666667em 0em 0.6666666667em 2.5333333333em;
  border: 1px solid #CCCCCC;
  border-top: none;
}
.sidebar-multicates--style-02 .sidebar__items > ul > li > a strong, .sidebar-multicates--style-02 .sidebar__items > ul > li > a small {
  display: block;
}
@media (max-width: 1024px) {
  .sidebar-multicates--style-02 .sidebar__items > ul > li > a {
    background-size: 1.1333333333em;
    padding-left: 32px;
  }
  .sidebar-multicates--style-02 .sidebar__items > ul > li > a small {
    display: none;
  }
}
.sidebar-multicates--style-02 .sidebar__items > ul > li li.col-4 {
  margin: 0;
}
@media (min-width: 1025px) {
  .sidebar-multicates--style-02 .sidebar__items > ul > li > ul {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #FFFFFF;
    z-index: 1;
    height: 100%;
    width: 40.6666666667em;
    border: 1px solid #CCCCCC;
    padding-top: 0.3333333333em;
    border-left: none;
  }
  .sidebar-multicates--style-02 .sidebar__items > ul > li > ul > li {
    float: left;
    padding-left: 25px;
    line-height: 2;
  }
  .sidebar-multicates--style-02 .sidebar__items > ul > li > ul > li > a {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 0.2em;
    display: block;
  }
  .sidebar-multicates--style-02 .sidebar__items > ul > li:hover a {
    border-right: none;
  }
  .sidebar-multicates--style-02 .sidebar__items > ul > li:hover > ul {
    display: block;
  }
}

.gallery {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.3333333333em;
}
.gallery__image {
  line-height: 0;
  position: relative;
}
.gallery__image:hover .overlay {
  opacity: 1;
}
.gallery__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  padding: 5px 10px;
  color: #fff;
  font-size: 1.4rem;
  opacity: 0;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
}
.gallery-title .overlay {
  background-color: rgba(0, 0, 0, 0.17);
}
.gallery:hover .gallery__title {
  opacity: 1;
}

.gallery-zoom {
  font-size: 1.5rem;
  background-color: #000;
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gallery-zoom.show {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.gallery-zoom .gallery_caption {
  position: absolute;
  bottom: 32px;
  left: 0;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  text-align: center;
  padding: 10px 0;
}
.gallery-zoom .gallery__controls {
  margin: 0 auto;
  height: 42px;
  width: 100%;
  bottom: 0px;
  left: 0;
  z-index: 4;
  /*background: url(/noithatboytan/public_html/images/icons/gallery-nav-bg.png) repeat-x;*/
  position: fixed;
}
.gallery-zoom .gallery__controls .controls {
  overflow: hidden;
  height: 100%;
  position: relative;
  text-align: left;
  z-index: 5;
}
.gallery-zoom .gallery__controls .controls .play-pause, .gallery-zoom .gallery__controls .controls .btn-close, .gallery-zoom .gallery__controls .controls .btn-slide {
  float: left;
  margin-top: 1px;
  border-right: 1px solid #333;
  font-size: 140%;
}
.gallery-zoom .gallery__controls .controls .btn-close, .gallery-zoom .gallery__controls .controls .btn-slide {
  float: right;
  border-left: 1px solid #333;
}
.gallery-zoom .gallery__controls .controls .slidecounter {
  float: left;
  color: #999;
  text-shadow: #000 0 -1px 0;
  margin: 0px 10px 0;
  line-height: 42px;
}
.gallery-zoom .gallery__controls .controls .slidecaption {
  overflow: hidden;
  float: left;
  color: #FFF;
  text-shadow: #000 1px 1px 2px;
  margin: 0 20px 0 0;
  line-height: 42px;
}
.gallery-zoom .slick-slider {
  max-height: none;
  height: 100%;
}
.gallery-zoom .slick-slider .slick-track, .gallery-zoom .slick-slider .slick-list {
  height: inherit;
}
.gallery-zoom .slick-slide {
  position: relative;
}
.gallery-zoom .slick-dots {
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.gallery-zoom .slick-dots li {
  float: left;
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #333333;
  cursor: pointer;
}
.gallery-zoom .slick-dots li button {
  padding: 0;
  text-indent: -9999999px;
}
.gallery-zoom .slick-dots li:hover, .gallery-zoom .slick-dots li.slick-active {
  background-color: #FFFFFF;
}
@media (max-width: 1024px) {
  .gallery-zoom .slick-dots {
    display: none !important;
  }
}

.gallery-1-nav.slick-slider {
  max-height: none;
}
.gallery-1-nav .slick-track {
  width: 100% !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.gallery-zoom .gallery-2-nav {
  width: 100%;
  position: absolute;
  bottom: -100%;
  height: auto;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
}
.gallery-zoom .gallery-2-nav.show {
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
  bottom: 42px;
}
.gallery-zoom .gallery-2-nav .slick-slide .overlay {
  opacity: 1;
}
.gallery-zoom .gallery-2-nav .slick-current .overlay {
  opacity: 0;
}

.overlay-transparent {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}


/*them vao sass*/
.is-hide{
  display: none;
}
.card--products-min-height{
  min-height: 305px;
}
/*luu ý thêm vào sass*/
.is-error--text {
  margin-bottom: 25px;
}
.is-error .error-text {
    color: #f55145;
    display: block;
    font-size: 12px;
    font-style: italic;
    position: absolute;
    right: 0;
}
.loading_gif{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background-color: rgba(51, 51, 51, 0.31);
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
[class*="c-icon"] {
  display: inline-block;
  position: relative;
  font-size: 13px;
  width: 1.54em;
  height: 1.54em;
  color: #999999;
  border-radius: 3px;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
[class*="c-icon"] i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
[class*="c-icon"]:hover {
  opacity: 0.7;
}

[class*="c-icon-bg"] {
  background-color: #999999;
  color: #FFFFFF;
}
[class*="c-icon-bg"] + [class*="c-icon-bg"] {
  margin-left: 5px;
}

[class*="c-icon-border"] {
  border: 1px solid #CCCCCC;
}
[class*="c-icon-border"] + [class*="c-icon-border"] {
  margin-left: 5px;
}

[class*="c-icon-img"] {
  background-size: 0.7em;
  background-repeat: no-repeat;
  background-position: center;
}

.c-icon--fb {
  color: #365899;
}
.c-icon--gl {
  color: #dd4b39;
}
.c-icon--tw {
  color: #55acee;
}
.c-icon--pr {
  color: #D63633;
}
.c-icon--ins {
  color: #cc2127;
}
.c-icon--yt {
  color: #e52d27;
}
.c-icon--in {
  color: #0976b4;
}
.c-icon--zalo {
  color: #0180C6;
}
.c-icon--skype {
  color: #00AFF0;
}
.c-icon--white {
  color: #FFFFFF;
}
.c-icon--primary {
  color: #FD102A;
}
.c-icon--secondary {
  color: #FF6600;
}
.c-icon-circle {
  border-radius: 100%;
}
.c-icon-border--fb {
  border-color: #365899;
}
.c-icon-border--gl {
  border-color: #dd4b39;
}
.c-icon-border--tw {
  border-color: #55acee;
}
.c-icon-border--pr {
  border-color: #D63633;
}
.c-icon-border--ins {
  border-color: #cc2127;
}
.c-icon-border--yt {
  border-color: #e52d27;
}
.c-icon-border--in {
  border-color: #0976b4;
}
.c-icon-border--zalo {
  border-color: #0180C6;
}
.c-icon-border--skype {
  border-color: #00AFF0;
}
.c-icon-border--white {
  border-color: #FFFFFF;
}
.c-icon-border--primary {
  border-color: #FD102A;
}
.c-icon-border--secondary {
  border-color: #FF6600;
}
.c-icon-border--fb {
  color: #365899;
}
.c-icon-border--gl {
  color: #dd4b39;
}
.c-icon-border--tw {
  color: #55acee;
}
.c-icon-border--pr {
  color: #D63633;
}
.c-icon-border--ins {
  color: #cc2127;
}
.c-icon-border--yt {
  color: #e52d27;
}
.c-icon-border--in {
  color: #0976b4;
}
.c-icon-border--zalo {
  color: #0180C6;
}
.c-icon-border--skype {
  color: #00AFF0;
}
.c-icon-border--white {
  color: #FFFFFF;
}
.c-icon-border--primary {
  color: #FD102A;
}
.c-icon-border--secondary {
  color: #FF6600;
}
.c-icon-bg--fb {
  background-color: #365899;
}
.c-icon-bg--gl {
  background-color: #dd4b39;
}
.c-icon-bg--tw {
  background-color: #55acee;
}
.c-icon-bg--pr {
  background-color: #D63633;
}
.c-icon-bg--ins {
  background-color: #cc2127;
}
.c-icon-bg--yt {
  background-color: #e52d27;
}
.c-icon-bg--in {
  background-color: #0976b4;
}
.c-icon-bg--zalo {
  background-color: #0180C6;
}
.c-icon-bg--skype {
  background-color: #00AFF0;
}
.c-icon-bg--white {
  background-color: #FFFFFF;
}
.c-icon-bg--primary {
  background-color: #FD102A;
}
.c-icon-bg--secondary {
  background-color: #FF6600;
}
.c-icon-img--fb {
  background-color: #365899;
}
.c-icon-img--gl {
  background-color: #dd4b39;
}
.c-icon-img--tw {
  background-color: #55acee;
}
.c-icon-img--pr {
  background-color: #D63633;
}
.c-icon-img--ins {
  background-color: #cc2127;
}
.c-icon-img--yt {
  background-color: #e52d27;
}
.c-icon-img--in {
  background-color: #0976b4;
}
.c-icon-img--zalo {
  background-color: #0180C6;
}
.c-icon-img--skype {
  background-color: #00AFF0;
}
.c-icon-img--white {
  background-color: #FFFFFF;
}
.c-icon-img--primary {
  background-color: #FD102A;
}
.c-icon-img--secondary {
  background-color: #FF6600;
}
.c-icon-img--fb {
  border-color: #365899;
}
.c-icon-img--gl {
  border-color: #dd4b39;
}
.c-icon-img--tw {
  border-color: #55acee;
}
.c-icon-img--pr {
  border-color: #D63633;
}
.c-icon-img--ins {
  border-color: #cc2127;
}
.c-icon-img--yt {
  border-color: #e52d27;
}
.c-icon-img--in {
  border-color: #0976b4;
}
.c-icon-img--zalo {
  border-color: #0180C6;
}
.c-icon-img--skype {
  border-color: #00AFF0;
}
.c-icon-img--white {
  border-color: #FFFFFF;
}
.c-icon-img--primary {
  border-color: #FD102A;
}
.c-icon-img--secondary {
  border-color: #FF6600;
}
.c-icon--sm {
  font-size: 1rem;
}
.c-icon--lg {
  font-size: 1.5rem;
}